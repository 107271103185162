import React from "react";

const Company = (props, context) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34.449" height="32.027" viewBox="0 0 34.449 32.027">
            <g id="그룹_8561" data-name="그룹 8561" transform="translate(-711.301 -255.71)">
                <rect
                    id="사각형_6351"
                    data-name="사각형 6351"
                    width="24.966"
                    height="24.135"
                    rx="3.533"
                    transform="translate(720.785 263.602)"
                    fill="#1580f8"
                />
                <rect
                    id="사각형_6352"
                    data-name="사각형 6352"
                    width="17.428"
                    height="32.027"
                    rx="3.533"
                    transform="translate(711.301 255.71)"
                    fill="#ddedff"
                />
                <rect id="사각형_6353" data-name="사각형 6353" width="3.922" height="3.922" transform="translate(714.792 260.11)" fill="#8cc2fd" />
                <rect id="사각형_6354" data-name="사각형 6354" width="3.922" height="3.922" transform="translate(721.982 260.11)" fill="#8cc2fd" />
                <rect id="사각형_6355" data-name="사각형 6355" width="3.922" height="3.922" transform="translate(731.047 270.395)" fill="#ddedff" />
                <rect id="사각형_6356" data-name="사각형 6356" width="3.922" height="3.922" transform="translate(737.963 270.395)" fill="#ddedff" />
                <rect id="사각형_6357" data-name="사각형 6357" width="3.922" height="3.922" transform="translate(731.047 277.283)" fill="#ddedff" />
                <rect id="사각형_6358" data-name="사각형 6358" width="3.922" height="3.922" transform="translate(737.963 277.283)" fill="#ddedff" />
                <rect id="사각형_6359" data-name="사각형 6359" width="3.922" height="3.922" transform="translate(714.792 267.099)" fill="#8cc2fd" />
                <rect id="사각형_6360" data-name="사각형 6360" width="3.922" height="3.922" transform="translate(721.982 267.099)" fill="#8cc2fd" />
                <rect id="사각형_6361" data-name="사각형 6361" width="3.922" height="3.922" transform="translate(714.792 274.1)" fill="#8cc2fd" />
                <rect id="사각형_6362" data-name="사각형 6362" width="3.922" height="3.922" transform="translate(721.982 274.1)" fill="#8cc2fd" />
                <rect id="사각형_6363" data-name="사각형 6363" width="3.922" height="3.922" transform="translate(714.792 280.636)" fill="#8cc2fd" />
                <rect id="사각형_6364" data-name="사각형 6364" width="3.922" height="3.922" transform="translate(721.982 280.636)" fill="#8cc2fd" />
            </g>
        </svg>
    );
};

export default Company;
