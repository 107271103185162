export const EN = {
    "이 Open API 는 Glosign팀에서 관리되며 Glosign 회원 가입 후 API 연동을 진행 하실 수 있습니다.":
        "This Open API is supervised by Glosign. You can proceed with API integration after registering for our services.",
    "현재 제공되고 있는 API 인증 방식": "Current API verification method in service",
    "api키는 bearer 방식으로 glosign admin 페이지에 api_key가 제공되고 있습니다.":
        "The api_key is provided on the Glosign Adminstrator page in bearer form.",
    "글로싸인 API 문서입니다.": "This is Glosign’s API document.",
    "글로싸인 바로가기": "Go to Glosign",
    "유저에 관련된 API": "User API",
    "계약에 관련된 API": "Contract API",
    "서식에 관련된 API": "Template API",
    "기업에 관련된 API (API 전용)": "Corporate API (API only)",
    "문서에 관련된 API": "Document API",
    "유저 관리": "User Management",
    "계약 관리": "Contract Management",
    "서식 관리": "Template Management",
    "기업 관리": "Company Management",
    "문서 관리": "Document Management",
    "유저 조회": "Search user",
    "유저 상세정보 조회": "Search specific user information",
    성공: "success",
    "유저 ID": "user ID",
    "유저 이메일": "user email",
    유저명: "user name",
    "유저 기업 코드": "user’s company code",
    "서버 오류": "server error",
    "유저 기업 조회": "Search user's company",
    "유저가 소유한 기업 조회": "Search for company owned by user",
    기업명: "company name",
    소재지: "location",
    "기업 주소": "company address",
    "산업 분야": "industry type",
    전화번호: "phone number",
    "기업 형태\n* 01 - 개인사업자\n* 02 - 법인사업자\n* 03 - 간이사업자\n* 04 - 유한사업자\n":
        "company type\n* 01 - self-employed\n* 02 - corporate\n* 03 - small business\n* 04 - limited\n",
    "기업 규모": "company size",
    "기업 인증 여부": "confirm corporate verification",
    사업자번호: "company number",
    "서명 조회": "Search signature",
    "서명 타입": "signature type",
    서명id: "contract ID",
    "이미지 url": "image url",
    생성일: "creation date",
    수정일: "edited date",
    "메인 여부": "confirm main signature",
    "조회된 도장이 없음": "no seals match search result",
    "이미지 오브젝트": "image object",
    "기업 서명 여부": "company signature present/absent confirmation",
    "parameter가 잘못됐을 경우": "case of parameter malfunction",
    "서명 등록": "Register signature",
    "서명 삭제": "Delete signature",
    "전체 계약 조회": "Search all contracts",
    "계약 상세 조회": "Search contract specifications",
    "계약 재전송": "Resend contract",
    "검토 완료 계약 진행": "Proceed with contract after completing review",
    "계약 취소": "Cancel contract",
    "서명 정보 조회": "Search signature information",
    "계약 사본파일 다운로드": "Download copy of the contract",
    "서식 리스트 조회": "Search template list",
    "기업 서식 리스트 조회": "Search company template list",
    "서식 상세 조회": "Search template specifications",
    "서식 삭제": "Delete template",
    "서식으로 계약 생성": "Create contract with template",
    "서식 데이터로 미리보기 (Beta)": "Preview with template data (Beta)",
    "기업 생성": "Create company",
    "기업 삭제": "Delete company",
    "기업 구성원 조회": "Search company member",
    "기업 구성원 추가": "Add company member",
    "기업 구성원 삭제": "Delete company member",
    "기업 메일 조회": "Search company email",
    "허용되지 않은 access key을 사용하여 API 전송시 오류": "error for using unauthorized access key when sending API",
    "유저가 가지고 있는 서명 조회": "Search for user’s signature",
    "로컬 이미지를 이용하여 서명 등록": "Register signature using local image",
    "유저가 가지고 있는 서명 삭제": "Delete user’s signature",
    "서명 ID": "signature ID",
    "유저가 가지고 있는 모든 계약 리스트": "All of the user’s contract list",
    시작일: "start date",
    종료일: "end date",
    "계약 상태\n* `send` - 계약 전송\n* `review` - 계약 검토 중\n* `reviewComplete` - 계약 검토 완료\n* `contracting` - 계약 진행 중\n* `complete` - 계약 완료\n* `cancel` - 계약 취소\n":
        "contract status\n* `send` - send contract\n* `review` - contract review in progress\n* `reviewComplete` - contract review complete\n* `contracting` - contract in progress\n* `complete` - contract complete\n* `cancel` - cancel contract\n",
    "구성원 ID": "member ID",
    "계약 조회 완료": "contract search complete",
    "계약 리스트": "contract list",
    "계약 ID": "contract ID",
    계약명: "contract title",
    "계약 상태\n* `send` - 계약 전송\n* `contracting` - 계약 진행 중\n* `complete` - 계약 완료\n* `cancel` - 계약 취소\n":
        "contract status\n* `send` - send contract\n* `contracting` - contract in progress\n* `complete` - contract complete\n* `cancel` - cancel contract\n",
    "전체 메세지": "all messages",
    "서명 순서 여부": "confirm signature order",
    "계약 완료일": "contract completion date",
    "계약 방법": "contract method",
    "기타완료 여부": "confirm other methods used to complete contract",
    "기타완료 사유": "reasons for using other methods",
    "조회된 내역이 없을 경우": "no search results found",
    "해당 계약의 상세정보 조회": "Search specifications of the respective contract",
    참여자: "participant",
    "서명 순서": "signature order",
    참여자명: "participant name",
    "참여자 이메일": "participant email",
    "참여자 핸드폰 국가번호": "country code of participant’s phone number",
    "참여자 핸드폰 번호": "participant’s phone number",
    "참여자 서명 상태\n* `step1` - 서명 필요\n* `step2` - 서명 완료\n* `step3` - 계약 완료\n* `step4` - 계약 거절\n* `step5` - 계약 취소\n* `step6` - 계약 만료\n* `step10` - 검토 필요\n* `step11` - 검토 완료\n":
        "participant signature status\n* `step1` - requires signature\n* `step2` - signature complete\n* `step3` - contract complete\n* `step4` - reject signature\n* `step5` - cancel contract\n* `step6` - contract expired\n* `step10` - requires review\n* `step11` - review complete\n",
    "발송자 여부": "confirm sender",
    "참여자 서명 완료 여부": "confirm participant signature completion",
    "참여자 서명 완료 시간": "participant’s signature completion time",
    "참여자 개인 메세지": "participant’s personal message",
    만료일: "expiration date",
    "서명 url": "signature url",
    첨부파일: "attached file",
    파일명: "file name",
    "첨부파일 설명": "description of attached file",
    파일: "file",
    "진행 중인 계약을 재전송": "Resend the contract in progress",
    "이메일 전송 여부": "email sent/not sent confirmation",
    "SMS/Kakao 전송 여부": "SMS/Kakao sent/not sent confirmation",
    "검토가 완료된 계약을 서명단계로 진행": "Proceed to the signature step after completing review",
    메세지: "message",
    "request값 또는 계약 상태가 잘못됐을 경우": "case of erroneous contract status or request value",
    "선택된 계약을 취소": "Cancel selected contract",
    "완료된 계약의 서명 정보 조회": "Search for signature information on completed contract",
    "참여자 데이터": "participant data",
    이름: "name",
    이메일: "email",
    핸드폰번호: "phone number",
    핸드폰국가: "country",
    조건: "condition",
    "조건 타입": "condition type",
    "조건 id": "condition id",
    "문서 정보": "document information",
    문서명: "document title",
    "문서 페이지 번호": "document page number",
    "필수 여부": "required/optional confirmation ",
    "서명완료 여부": "confirm signature completion",
    "계약 정보 조회 시 오류": "error when searching for contract information",
    "완료된 계약의 사본파일 다운로드": "Download the copy of the completed contract",
    "파일 데이터": "file data",
    "개인 서식 리스트 조회": "Search personal template list",
    "계약 데이터 리스트": "contract data list",
    "서식 ID": "template ID",
    서식명: "template title",
    "서식 설명": "template description",
    "서식 상태\n* step0 - 미완성\n* step1 - 완성\n* step3 - 미리서명":
        "template status\n* step0 - incomplete\n* step1 - complete\n* step3 - sign beforehand",
    "참여자 수": "number of participants",
    "계약 조회 서버 오류": "server error when searching contract",
    "기업 코드": "company code",
    "서식 상세 정보 조회": "Search template specifications",
    "서식 데이터": "template data",
    "해당 서식 삭제": "Delete the following template",
    "서식 삭제 성공": "successfully deleted template",
    "삭제할 서식이 존재하지 않음": "the contract to be deleted does not exist",
    "계약 삭제 시 서버 오류": "server error when deleting contract",
    "기존 서식을 통해 새로운 계약 생성": "Use existing template to create a new contract",
    "템플릿 ID": "template ID",
    "공통 계약명": "common contract title",
    "공통 메세지": "common message",
    "공통 만료일": "common expiration date",
    "공통 언어": "common language",
    "인증서 언어": "certification language",
    "메일 스타일 id": "email style id",
    "발신자 id": "sender id",
    미리서명자: "pre-signer",
    "테이블 데이터": "table data",
    "계약 전송 성공": "successfully sent contract",
    "계약 상태": "contract status",
    "계약 가능 수량 부족 또는 parameter가 잘못된 경우": "case of contract/parameter malfunction or lacking available credits",
    "계약 생성 시 서버 오류": "server error when creating contract",
    "미리서명된 서식으로 미리서명값 매핑하여 미리보기":
        "Preview by mapping the signature value with a template that already has your signature present",
    "기업을 추가 생성한다": "Add another company",
    주소: "address",
    "기업 종류\n* 01 - 개인사업자\n* 02 - 법인사업자\n* 03 - 간이사업자\n* 04 - 유한사업자":
        "company type\n* 01 - self-employed\n* 02 - corporate\n* 03 - small business\n* 04 - limited",
    "기업 전화번호 국가": "country code for company number",
    "기업 전화번호": "company number",
    "기업 국가": "country of where company is located",
    "추가 등록된 기업을 삭제한다": "Delete the additionally registered company",
    "구성원 권한": "member authority",
    "권한 상세\n* create - 생성\n* read - 읽기": "specifications on authorization\n* create - create\n* read - read",
    "기업 메일 리스트 조회": "Search company’s email list",
    "더 전문적으로, 더 효율적으로.": "More professionally and efficiently.",
    "조직의 환경과 필요에 맞는 글로싸인 기업 특화 서비스를 통해 달라진 업무환경을 경험해보세요.":
        "Experience a transformed work environment with Glosign’s corporate services tailored to your company’s needs and environment.",
    "글로싸인 API, 어떤 계약이든 적용하세요.": "Apply Glosign API to any type of contract.",
    "Token 발급": "Issue Token",
    "1. 글로싸인 사이트(": "1. Access our website (",
    ")에 접속, 로그인 후 상단 오른쪽 이름 클릭": "), log in, then click on your username at the top right.",
    "2. 오른쪽 메뉴에서 관리자 클릭": "2. Click on Administrator on the menu at the right.",
    "3. 왼쪽 API 메뉴 클릭 후 REST API 키 발행": "3. Click on the API menu on the left, then issue the REST API key.",
    "javascript sdk는 Glosign이 가지고 있는 기능들을 javascript 를 통해 이용 가능합니다.":
        "You can access javascript sdk by using Glosign functions on javascript.",
    "현재 테스트 모드를 지원하지 않습니다.": "We currently do not support test mode.",
    "Glosign SDK를 사용하기 위한 필수 javascript입니다.": "The following javascript is required for your use of Glosign’s SDK.",
    "body 하단 또는 header script 부분에 삽입해주세요.": "Please insert below the body or at the header script.",
    "싸인 만들기, 도장 만들기 팝업을 제공합니다.": "We offer pop-ups on creating signature and seal.",
    "필수 설정": "Required Setting",
    "서명,도장 만들기 Popup": "Pop-up on creating signature/seal",
    "서명 방법": "signature method",
    "서명 이력 표시": "show signature history",
    예시: "Example",
    "계약 시작하기, 문서 열람 등 핵심 기능을 제공합니다.":
        "We offer vital functions such as ones that allow user to open file, start contracts, etc.",
    "계약 시작하기": "Start contract",
    "비대면 계약, 대면 계약을 시작하기 위한 기본적인 뷰어입니다.":
        "This is the standard viewer that allows users to create online/in-person contracts.",
    "을 통해 참여자를 미리 등록하여 뷰어를 활용할 수 있습니다.": "You can use the viewer by registering participants beforehand via ",
    선택사항: "Optional",
    "팝업 종류": "pop-up type",
    "viewer가 삽입될 element id 또는 element object": "element id/element object in which viewer will be inserted",
    "가로 크기": "width",
    "세로 크기": "length",
    "서명 참여자": "signature participant",
    "사용자 식별 ID": "user ID",
    "서식 수정/만들기": "Create/edit template",
    "반복적인 계약을 서식으로 저장해두고 재사용하기 위한 서식 만들기 뷰어입니다.":
        "The viewer allows you to reuse saved templates for repetitive contracts.",
    "templateId를 넣으면 서식을 수정할 수 있습니다.": "You can edit the template if you enter the template ID.",
    "모두 가능합니다.": "Such can be done for both ",
    기업서식: "company",
    개인서식: " and individual templates.",
    "서식 ID": "template ID",
    "문서에 서명하기": "Sign document",
    "현재 대면계약만 가능": "currently only in-person is available",
    "계약 전송 후 서명하기 위한 뷰어입니다.": "This is the viewer you need to sign the contract that has been sent.",
    "이메일/SMS등 알림을 원치않고 생성된 계약서에 자체적으로 서명만 하고싶을 때 활용가능합니다.":
        "If you do not want any email, SMS, etc. notifications and are only interested in the act of signing the contract itself, use the following. ",
    "기업 어드민 관리": "Corporate administrator management",
    "기업 전체적인": "This administrator viewer allows you to ",
    "이메일 관리, 계약 관리를": "manage the overall emails and contracts ",
    "하기 위한 어드민 뷰어입니다.": "of the company.",
    "계약 문서 미리보기": "Preview contract document",
    "계약이 진행 중인 문서를 미리 볼 수 있습니다.": "You can preview the contract document in progress.",
    "계약ID 또는 서식ID": "contract ID or template ID",
    "계약 완료 전 문서는 기본 문서만 보이고, 계약이 완료 됐을 시 서명이 완료된 문서로 보입니다.":
        "A contract in progress will appear as the original document you’ve initially uploaded, and will only appear as a signed, completed document once the contract has been closed.",
    폴더: "folder",
    폴더명: "folder name",
    역할명: "role title",
    "참여자 순서": "order of participants",
    "조건 리스트": "list of conditions",
    조건명: "condition title",
    조건값: "condition value",
    조건타입: "condition type",
    미리보기: "preview",
    "추가 문서 리스트": "list of additional documents",
    "추가 문서": "additional document",
    "추가 첨부파일 리스트": "list of additional attached files",
    "추가 첨부파일": "additional attached file",
    "서명 순서설정": "signature order setting",
    "계약 검토여부": "confirm contract review",
    "개별 계약명": " individual contract title ",
    "미리서명 조건": "condition to sign beforehand",
    "입력 데이터": "input data",
    "텍스트 정렬(텍스트일 경우)": "align text (in the case of a text)",
    "서명자 리스트": "signature list",
    "계약자 순번 ( 1부터 시작 )": "contractor line-up (starting from 1)",
    국가번호: "country code",
    국가: "country",
    "개인 메세지": "personal message",
    접근설정: "access settings",
    "접근 타입": "access type",
    "암호 또는 전화번호": "passcode or phone number",
    본인인증여부: "confirm self-verification",
    "완료 후 이동할 url": "click following url after completion",
    "서명 조건 리스트": "list of signature conditions",
    "첨부요청 리스트": "list of attached files requests",
    "첨부 요청명": "name of attached file request ",
    "테이블 id": "table id",
    "테이블 헤더 스타일": "table header style",
    "헤더 높이": "header height",
    "테이블 푸터 스타일": "table footer style",
    "푸터 높이": "footer height",
    "테이블 내용 데이터": "table content data",
    "content: 테이블 내용; sub_total: 소계": "content: table content; sub_total: subtotal",
    "Row 스타일": "row style",
    "Row 높이": "row height",
    "행 순번": "row line-up",
    "셀 내용": "cell content",
    "열 순번": "column line-up",
    "열 병합": "merge column",
    "가로 정렬": "align horizontally",
    "세로 정렬": "align vertically",
    글자크기: "text size",
    "테이블 꼬릿말 데이터": "table footnote data",
    만료일자: "expiration date",
    "서명 순서 여부": "confirm signature order",
    완료일: "completion date",
    기타완료여부: "confirm other methods used to complete contract",
    참여자리스트: "participant list",
    핸드폰: "phone number",
    서명URL: "signature url",
    "미리보기 Id": "preview ID",
    "구성원 식별 ID": "member ID",
    "권한 타입": "authorization type",
    "유저 식별id": "user ID",
    "메일 식별 ID": "email ID",
    제목: "title",
    설명: "description",
    "메일 식별 ID": "email ID",
    제목: "title",
    설명: "description",
    "발송자 이메일": "sender email",
    "생성한 option": "created option",
    "도장 모양": "seal shape",
    언어: "language",
    "도장 생성 텍스트": "text on seal",
    "key를 입력하세요.": "Insert api key",
    "API를 테스트할 수 있는 기능 입니다.": "This function allows you to test API.",
    "메뉴 오른쪽 상단에 api key를 설정 후 사용해 주세요.": "Please use after setting up the API key on the top right menu.",
    "*표시는 필수조건": "*indicates the following condition is a requirement",
    "선택된 파일 없음": "no file has been selected",
    "파일 업로드": "upload file",
    "API Key를 확인해주세요.": "Please check the API Key.",
    "복사되었습니다.": "copied.",
    "API 소개": "API",
};
