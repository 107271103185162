import React, { useState } from "react";
import * as styles from "./styles.scss";
import { Link } from "react-router-dom";
import * as SVG from "components/SVG";
import Language from "components/Language";

import { useTranslation } from "react-i18next";

export const HeadNavigation: React.FC<HeadProps> = (props) => {
    const { t } = useTranslation();
    const [hover, setHover] = useState("");

    const _handleHover = (type: string) => {
        setHover(type);
    };

    const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChangeInput && props.handleChangeInput(e);
    };

    return (
        <div className={styles.naviWrap}>
            <div className={styles.wrap}>
                <div className={styles.left}>
                    <div className={styles.left}>
                        <div className={styles.mobileMenu} onClick={props.handleMobileMenu}>
                            <SVG.Menu />
                        </div>
                        <div className={styles.logo}>
                            <Link to="/api">
                                <img src="https://www.glosign.com/static/media/newindex/glosign-logo-white.svg" alt="glosign logo" />
                            </Link>
                            <div className={styles.apiBox}>API</div>
                            <div className={styles.webLang}>
                                <Language />
                            </div>
                        </div>
                    </div>
                    <div className={styles.mobileLang}>
                        <Language />
                    </div>
                </div>
                <div className={styles.webMenu}>
                    <ul>
                        <li
                            className={[styles.list, props.currentPage === "intro" ? styles.current : hover === "intro" ? styles.active : null].join(
                                " "
                            )}
                            onMouseEnter={() => _handleHover("intro")}
                            onMouseLeave={() => _handleHover("")}
                        >
                            <Link to="/api">{t("API 소개")}</Link>
                        </li>
                        <li
                            className={[styles.list, props.currentPage === "guide" ? styles.current : hover === "guide" ? styles.active : null].join(
                                " "
                            )}
                            onMouseEnter={() => _handleHover("guide")}
                            onMouseLeave={() => _handleHover("")}
                        >
                            <Link to="/guide">API GUIDE</Link>
                        </li>
                        {/* <li
                            className={[styles.list, props.currentPage === "sdk" ? styles.current : hover === "sdk" ? styles.active : null].join(" ")}
                            onMouseEnter={() => _handleHover("sdk")}
                            onMouseLeave={() => _handleHover("")}
                        >
                            <Link to="/sdk">API SDK</Link>
                        </li> */}
                    </ul>
                </div>
                {props.currentPage !== "intro" && (
                    <div className={styles.apiKey}>
                        <div>API Key</div>
                        <input id="api_key" type="text" placeholder={t("key를 입력하세요.")} value={props.value} onChange={(e) => handleKeyChange(e)} />
                        {/* <button className={styles.keyBtn}>저장..?</button> */}
                    </div>
                )}
            </div>
            <div className={styles.mobileMenu}>
                <ul>
                    <li
                        className={[styles.list, props.currentPage === "intro" ? styles.current : hover === "intro" ? styles.active : null].join(" ")}
                        onMouseEnter={() => _handleHover("intro")}
                        onMouseLeave={() => _handleHover("")}
                    >
                        <Link to="/api">{t("API 소개")}</Link>
                    </li>
                    <li
                        className={[styles.list, props.currentPage === "guide" ? styles.current : hover === "guide" ? styles.active : null].join(" ")}
                        onMouseEnter={() => _handleHover("guide")}
                        onMouseLeave={() => _handleHover("")}
                    >
                        <Link to="/guide">API GUIDE</Link>
                    </li>
                    {/* <li
                        className={[styles.list, props.currentPage === "sdk" ? styles.current : hover === "sdk" ? styles.active : null].join(" ")}
                        onMouseEnter={() => _handleHover("sdk")}
                        onMouseLeave={() => _handleHover("")}
                    >
                        <Link to="/sdk">API SDK</Link>
                    </li> */}
                </ul>
            </div>
        </div>
    );
};
interface HeadProps {
    currentPage?: string;
    value?: string;

    handleMobileMenu?: () => void;
    handleChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export default HeadNavigation;
