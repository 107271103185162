import React from "react";

const Payment = (props, context) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="prefix__그룹_8399"
            width="22.657"
            height="34.357"
            data-name="그룹 8399"
            viewBox="0 0 22.657 34.357"
        >
            <rect
                id="prefix__사각형_5958"
                width="22.657"
                height="34.34"
                data-name="사각형 5958"
                rx="2"
                transform="translate(0 .002)"
                style={{ fill: "#8cc2fd" }}
            />
            <path id="prefix__사각형_5959" d="M0 0H5.732V34.357H0z" data-name="사각형 5959" transform="translate(4.72)" style={{ fill: "#1873c8" }} />
        </svg>
    );
};

export default Payment;
