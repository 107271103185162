import React from "react";

const Contract = (props, context) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="prefix__그룹_8483"
            width="39.493"
            height="34.023"
            data-name="그룹 8483"
            viewBox="0 0 39.493 34.023"
        >
            <defs></defs>
            <rect
                id="prefix__사각형_5973"
                width="11.736"
                height="3.867"
                class="prefix__cls-1"
                data-name="사각형 5973"
                rx="1.933"
                transform="rotate(135 7.188 17.947)"
                style={{ fill: "#8cc2fd" }}
            />
            <rect
                id="prefix__사각형_5974"
                width="11.736"
                height="3.867"
                class="prefix__cls-1"
                data-name="사각형 5974"
                rx="1.933"
                transform="rotate(135 6.283 16.097)"
                style={{ fill: "#8cc2fd" }}
            />
            <rect
                id="prefix__사각형_5975"
                width="11.736"
                height="3.867"
                class="prefix__cls-1"
                data-name="사각형 5975"
                rx="1.933"
                transform="rotate(135 5.482 14.163)"
                style={{ fill: "#8cc2fd" }}
            />
            <rect
                id="prefix__사각형_5976"
                width="11.736"
                height="3.867"
                class="prefix__cls-1"
                data-name="사각형 5976"
                rx="1.933"
                transform="rotate(135 4.681 12.23)"
                style={{ fill: "#8cc2fd" }}
            />
            <path
                id="prefix__사각형_5977"
                d="M0 0H17.603V15.404H0z"
                class="prefix__cls-1"
                data-name="사각형 5977"
                transform="rotate(135 13.836 15.196)"
                style={{ fill: "#8cc2fd" }}
            />
            <rect
                id="prefix__사각형_5978"
                width="11.736"
                height="3.867"
                class="prefix__cls-2"
                data-name="사각형 5978"
                rx="1.933"
                transform="rotate(45 -5.03 37.938)"
                style={{ fill: "#ddedff" }}
            />
            <rect
                id="prefix__사각형_5979"
                width="11.736"
                height="3.867"
                class="prefix__cls-2"
                data-name="사각형 5979"
                rx="1.933"
                transform="rotate(45 -9.735 36.326)"
                style={{ fill: "#ddedff" }}
            />
            <rect
                id="prefix__사각형_5980"
                width="11.736"
                height="3.867"
                class="prefix__cls-2"
                data-name="사각형 5980"
                rx="1.933"
                transform="rotate(45 -14.402 34.393)"
                style={{ fill: "#ddedff" }}
            />
            <rect
                id="prefix__사각형_5981"
                width="11.736"
                height="3.867"
                class="prefix__cls-2"
                data-name="사각형 5981"
                rx="1.933"
                transform="rotate(45 -19.07 32.46)"
                style={{ fill: "#ddedff" }}
            />
            <path
                id="prefix__사각형_5982"
                d="M0 0H17.603V15.404H0z"
                class="prefix__cls-2"
                data-name="사각형 5982"
                transform="rotate(45 1.623 21.891)"
                style={{ fill: "#ddedff" }}
            />
            <path
                id="prefix__선_728"
                d="M0 0L4.507 4.282"
                class="prefix__cls-3"
                data-name="선 728"
                transform="translate(25.259 20.172)"
                style={{ fill: "none", stroke: "#8cc2fd", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "4.19px" }}
            />
            <path
                id="prefix__선_729"
                d="M0 0L4.507 4.282"
                class="prefix__cls-3"
                data-name="선 729"
                transform="translate(22.563 22.868)"
                style={{ fill: "none", stroke: "#8cc2fd", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "4.19px" }}
            />
            <path
                id="prefix__선_730"
                d="M0 0L4.507 4.282"
                class="prefix__cls-3"
                data-name="선 730"
                transform="translate(19.765 25.666)"
                style={{ fill: "none", stroke: "#8cc2fd", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "4.19px" }}
            />
            <rect
                id="prefix__사각형_5983"
                width="17.359"
                height="7.123"
                class="prefix__cls-2"
                data-name="사각형 5983"
                rx="3.561"
                transform="rotate(45 13.363 32.958)"
                style={{ fill: "#ddedff" }}
            />
            <rect
                id="prefix__사각형_5984"
                width="17.359"
                height="7.123"
                data-name="사각형 5984"
                rx="3.561"
                transform="rotate(135 7.612 6.104)"
                style={{ fill: "#1873c8" }}
            />
            <path
                id="prefix__패스_9931"
                d="M548.975 494.945l-5.825-.169s-2.955.084-3.292 2.87-.675 5.065-.675 5.065a2.461 2.461 0 0 0 2.11 1.857c1.857.338 2.617-1.688 2.617-1.688l.591-3.461 9.877.928-1.351-3.545z"
                class="prefix__cls-1"
                data-name="패스 9931"
                transform="translate(-527.457 -487.578)"
            />
        </svg>
    );
};

export default Contract;
