import React from "react";
import * as styles from "./styles.scss";
import { useTranslation } from "react-i18next";

const DescBox: React.FC<DProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className={styles.wrap}>
            <div className={styles.descBox}>
                <div className={styles.header}>
                    <h1 className={[styles.title, props.header === true ? styles.fontBig : styles.fontSmall].join(" ")}>{props.title}</h1>
                    <p>{t(props.desc)}</p>
                </div>
                <div className={styles.script}>
                    {props.script ? <p>{t(`${props.script}`)}</p> : null}
                    <ul>
                        {props.items?.map((value, idx) => {
                            return <li key={idx}>{value}</li>;
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

interface DProps {
    title: string;
    desc: string;
    script?: string;
    items?: string[];
    header?: boolean;
}

export default DescBox;
