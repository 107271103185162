import React from "react";

const Close = (props, context) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="prefix__close-24px"
            width={props.w ? props.w : "24"}
            height={props.h ? props.h : "24"}
            viewBox="0 0 24 24"
        >
            <path id="prefix__패스_10526" d="M0 0h24v24H0z" data-name="패스 10526" style={{ fill: "none" }} />
            <path
                id="prefix__패스_10527"
                d="M18.3 5.71a1 1 0 0 0-1.41 0L12 10.59 7.11 5.7A1 1 0 0 0 5.7 7.11L10.59 12 5.7 16.89a1 1 0 0 0 1.41 1.41L12 13.41l4.89 4.89a1 1 0 0 0 1.41-1.41L13.41 12l4.89-4.89a1 1 0 0 0 0-1.4z"
                data-name="패스 10527"
                style={{ fill: props.fill ? props.fill : "#6980a6" }}
            />
        </svg>
    );
};

export default Close;
