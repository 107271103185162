import React, { useState } from "react";
import styles from "./styles.scss";
import * as file from "api/main.json";
import DescBox from "components/DescBox";

import { useTranslation } from "react-i18next";

const Authentication: React.SFC = (props) => {
    const { t } = useTranslation();
    const [mobileNavi, setMobileNavi] = useState(false);

    const _handleMobileMenu = () => {
        setMobileNavi(!mobileNavi);
    };

    return (
        <div className={styles.authBox}>
            <DescBox
                title="Authorization"
                header={true}
                desc={file.Authorization.description}
                script={file.Authorization.script.description}
                items={file.Authorization.script.items}
            />
            <APIkeyBox title="api_key" desc={file.Authorization.api_key.description} items={file.Authorization.api_key.items} />
            <div className={styles.block}>
                <div className={styles.blockTitle}>{t("Token 발급")}</div>
                <div className={styles.blockSubTitle}>
                    {t("1. 글로싸인 사이트(")}
                    <a href="https://www.glosign.com" target="_blank">
                        www.glosign.com
                    </a>
                    {t(")에 접속, 로그인 후 상단 오른쪽 이름 클릭")}
                </div>
                <img src={"static/media/help1.png"} alt="help1" />
                <div className={styles.line} />
                <div className={styles.blockSubTitle}>{t("2. 오른쪽 메뉴에서 관리자 클릭")}</div>
                <img src={"static/media/help2.png"} alt="help1" />
                <div className={styles.line} />
                <div className={styles.blockSubTitle}>{t("3. 왼쪽 API 메뉴 클릭 후 REST API 키 발행")}</div>
                <img src={"static/media/help3.png"} alt="help1" />
            </div>
        </div>
    );
};

const APIkeyBox: React.SFC<BoxProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className={styles.apikeyBox}>
            <div className={styles.header}>
                <h1 className={styles.title}>{props.title}</h1>
                <p>{t(props.desc)}</p>
            </div>
            <div className={styles.script}>
                <table>
                    <tbody>
                        {props.items?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    {item.map((value, idx) => {
                                        return (
                                            <td key={idx}>
                                                <span>{value}</span>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
interface BoxProps {
    title: string;
    desc: string;
    items?: string[][];
}

export default Authentication;
