import React, { useState } from "react";
import * as styles from "./styles.scss";
import * as file from "api/main.json";

import { useTranslation } from "react-i18next";

interface ResListProps {
    getBoxHeight: () => void;
    value: any; //json properties
    backColor?: boolean;
}

const ResList: React.FC<ResListProps> = (props) => {
    const { t } = useTranslation();
    const [subBox, setSubBox] = useState(false);

    const _handleSubBox = async () => {
        await setSubBox(!subBox);
        props.getBoxHeight();
    };

    // *수정* $ref의 경로만 불러서 접근할 수 있게 바꾸기
    const loc = file.components.schemas.stampItem.properties;
    return (
        <>
            {props.value.name ? (
                <>
                    <div className={styles.contractSubMenu}>
                        <div
                            className={styles.name}
                            style={props.value.items || props.value.properties ? { cursor: "pointer" } : undefined}
                            onClick={props.value.items || props.value.properties ? _handleSubBox : undefined}
                        >
                            <span>
                                <span />
                            </span>
                            <div>
                                {props.value.name}
                                {props.value.items || props.value.properties ? (
                                    <>
                                        <div className={styles.arrow} style={subBox ? { transform: "rotate(90deg)" } : undefined}>
                                            {">"}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div className={styles.value}>
                            <div className={styles.type}>
                                {props.value.type === "array" ? (props.value.items ? "Array of objects" : props.value.type) : props.value.type}
                            </div>
                            <pre className={styles.desc}>{t(props.value.description)}</pre>
                        </div>
                    </div>
                    {subBox && props.value.properties ? (
                        <div className={styles.subList} style={props.backColor ? { backgroundColor: "#fafbfd" } : { backgroundColor: "#fff" }}>
                            {props.value.properties.map((val: any) => {
                                return <ResList value={val} getBoxHeight={props.getBoxHeight} backColor={!props.backColor} />;
                            })}
                        </div>
                    ) : subBox && props.value.items?.properties ? (
                        <div className={styles.subList} style={props.backColor ? { backgroundColor: "#fafbfd" } : { backgroundColor: "#fff" }}>
                            {props.value.items.properties.map((val: any) => {
                                return <ResList value={val} getBoxHeight={props.getBoxHeight} backColor={!props.backColor} />;
                            })}
                        </div>
                    ) : subBox && props.value.items["$ref"] ? (
                        <div className={styles.subList} style={props.backColor ? { backgroundColor: "#fafbfd" } : { backgroundColor: "#fff" }}>
                            {loc.map((val: any) => {
                                return <ResList value={val} getBoxHeight={props.getBoxHeight} backColor={!props.backColor} />;
                            })}
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    {props.value.map((val: any) => {
                        return <ResList value={val} getBoxHeight={props.getBoxHeight} />;
                    })}
                </>
            )}
        </>
    );
};
export default ResList;
