import React, { useState } from "react";
import * as styles from "./styles.scss";
import * as file from "api/main.json";
import DescBox from "components/DescBox";

const Introduction: React.SFC<IProps> = (props) => {
    const [mobileNavi, setMobileNavi] = useState(false);

    const _handleMobileMenu = () => {
        setMobileNavi(!mobileNavi);
    };

    return (
        <div className={styles.introBox}>
            <DescBox title="Introduction" header={true} desc={file.info.introduction} items={file.info.items} />
        </div>
    );
};

interface IProps {}

export default Introduction;
