import React, { useState, useEffect } from "react";
import * as file from "api/main.json";
import styles from "./styles.scss";
import Box from "components/Box";
import LeftNavigation from "components/Navigation/LeftNavigation";
import HeadNavigation from "components/Navigation/HeadNavigation";
import SourceBox from "components/SourceBox";
import * as SVG from "components/SVG";
// import data from "api/main.yaml"

import GlosignAPI from "./GlosignAPI";
import Introduction from "./Introduction";
import Authentication from "./Authentication";

const Guide: React.SFC<IProps> = (props) => {
    const [mobileNavi, setMobileNavi] = useState(false);
    const [page, setPage] = useState("Glosign API");

    let titleList: string[] = ["Glosign API", "Introduction", "Authentication"];
    let urlList: string[] = ["/api", "/intro", "/auth"];

    useEffect(() => {
        // 새로고침 해도 페이지 유지되게 함
        let splitUrl = window.location.pathname.split("/");
        let temp: string = "";
        let idx: number = -1;

        splitUrl.map((url: string) => {
            if (url !== "") temp += "/" + url;
        });
        urlList.map((url: string, index: number) => {
            if (url === temp) idx = index;
        });
        if (idx !== -1) setPage(titleList[idx]);
    }, []);

    const _handleMobileMenu = () => {
        setMobileNavi(!mobileNavi);
    };

    return (
        <div className={styles.outerWrap}>
            <HeadNavigation currentPage="intro" handleMobileMenu={_handleMobileMenu} />
            <div className={[styles.navi, mobileNavi ? styles.mobileNavi : undefined].join(" ")}>
                <LeftNavigation type={page} urlList={urlList} onlyTitle={true} />
            </div>
            {mobileNavi ? <div className={styles.bg} onClick={_handleMobileMenu}></div> : null}
            <div className={styles.contentWrap} style={mobileNavi ? { overflow: "hidden" } : undefined}>
                {page === "Glosign API" ? <GlosignAPI /> : page === "Introduction" ? <Introduction /> : <Authentication />}
            </div>
        </div>
    );
};

interface IProps {}

export default Guide;
