import React from "react";
import styles from "./styles.scss";
import * as file from "api/main.json";
import * as SVG from "components/SVG";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const IntroBox: React.FC<IntroProps> = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={styles.introBox} style={i18n.language === "en" ? {height: "210px"} : undefined}>
            <div className={styles.left}>
                <div className={styles.image}>
                    <div>
                        {props.title === "user" ? (
                            <SVG.User />
                        ) : props.title === "payment" ? (
                            <SVG.Payment />
                        ) : props.title === "contract" ? (
                            <SVG.Contract />
                        ) : props.title === "template" ? (
                            <SVG.Template />
                        ) : props.title === "signature" ? (
                            <SVG.Signature />
                        ) : props.title === "company" ? (
                            <SVG.Company />
                        ) : props.title === "docs" ? (
                            <SVG.Docs />
                        ) : null}
                    </div>
                </div>
                <div className={styles.info}>
                    <h1 className={styles.title}>{i18n.language === "en" ? t(props.subtitle) : props.title + " " + props.subtitle}</h1>
                    <p className={styles.description}>{t(props.desc)}</p>
                </div>
            </div>
            <div className={styles.list}>
                <ul>
                    {file.paths.map((value: any) => {
                        let rest = Object.keys(value);
                        let restType = rest.filter((filter) => ["get", "post", "put", "delete"].includes(filter));
                        return restType.map((type, idx) => {
                            if (value[type].tags[0] && value[type].tags[0] === props.title) {
                                return (
                                    <Link to={"guide" + value.url + "/" + type}>
                                        <li key={idx}>{t(value[type].summary)}</li>
                                    </Link>
                                );
                            }
                        });
                    })}
                </ul>
            </div>
        </div>
    );
};

interface IntroProps {
    subtitle: string; //user,..
    title: string;
    desc: string;
}

export default IntroBox;
