import React, { useState } from "react";
import * as styles from "./styles.scss";

import { useTranslation } from "react-i18next";
import i18n from "../../config/lang/i18n";


const Language: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const curlang = i18n.language;
    const [openList,setOpenList] = React.useState(false);

    const handleChangeLang = (lang:string) => () => {
        if(curlang !== lang){
            console.log("i18n:",i18n)
            i18n && i18n.changeLanguage(lang);
        }
        setOpenList(false);
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.curLang} onClick={() => setOpenList(!openList)}>
                {["ko", "kr"].includes(curlang) ? (
                    <>
                        <img src={"/static/media/KO.png"} alt="flag" />
                        {"한국어"}
                    </>
                ) : curlang === "en" ? (
                    <>
                        <img src={"/static/media/US.png"} alt="flag" />
                        {"English"}
                    </>
                ) : (
                    <>
                        <img src={"/static/media/KO.png"} alt="flag" />
                        {"한국어"}
                    </>
                )}
                <svg
                    className={[styles.langFold, openList ? styles.open : undefined].join(" ")}
                    xmlns="http://www.w3.org/2000/svg"
                    width="5"
                    height="3"
                    viewBox="0 0 5 3"
                >
                    <path id="dropdown" d="M2.5 0L5 3H0z" className="cls-1" transform="rotate(180 2.5 1.5)" />
                </svg>
            </div>
            {openList ? (
                <>
                    <div className={styles.langList}>
                        <div className={styles.eachLang} onClick={handleChangeLang("ko")}>
                            <img src={"/static/media/KO.png"} alt="flag" />
                            {"한국어"}
                        </div>
                        <div className={styles.eachLang} onClick={handleChangeLang("en")}>
                            <img src={"/static/media/US.png"} alt="flag" />
                            {"English"}
                        </div>
                    </div>
                    <div className={styles.background} onClick={() => setOpenList(false)}/>
                </>
            ) : undefined}
        </div>
    )

}

interface IProps {
}
export default Language;