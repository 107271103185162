import React from "react";

const Copy = (props, context) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.w ? props.w : "16"} height={props.h ? props.h : "18.526"} viewBox="0 0 16 18.526">
            <path
                id="prefix__패스_10513"
                d="M12.947 1H3.684A1.689 1.689 0 0 0 2 2.684v10.948a.842.842 0 1 0 1.684 0V3.526a.845.845 0 0 1 .842-.842h8.421a.842.842 0 0 0 0-1.684zm.5 3.865l4.067 4.067A1.683 1.683 0 0 1 18 10.12v7.722a1.689 1.689 0 0 1-1.684 1.684H7.044a1.682 1.682 0 0 1-1.676-1.684l.009-11.789a1.682 1.682 0 0 1 1.676-1.685h5.2a1.689 1.689 0 0 1 1.191.497zm-.5 5.4h3.789l-4.631-4.633v3.789a.845.845 0 0 0 .842.842z"
                data-name="패스 10513"
                transform="translate(-2 -1)"
                style={{ fill: props.fill ? props.fill : "#6980a6" }}
            />
        </svg>
    );
};

export default Copy;
