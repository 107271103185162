import React from "react";

const Docs = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.263" height="33.457" viewBox="0 0 29.263 33.457">
            <g id="그룹_8569" data-name="그룹 8569" transform="translate(-332 -1507.094)">
                <g id="그룹_8568" data-name="그룹 8568" transform="translate(8511 -824.906)">
                    <path
                        id="패스_10805"
                        data-name="패스 10805"
                        d="M722.614,268.341h-7.6a2.689,2.689,0,0,0-2.681,2.681v15.041a2.688,2.688,0,0,0,2.681,2.681h23.9a2.688,2.688,0,0,0,2.681-2.681Z"
                        transform="translate(-8891.338 2073.661)"
                        fill="#1873c8"
                    />
                    <rect
                        id="사각형_6457"
                        data-name="사각형 6457"
                        width="23.26"
                        height="29.913"
                        transform="translate(-8176.286 2332)"
                        fill="#ddedff"
                    />
                    <rect
                        id="사각형_6458"
                        data-name="사각형 6458"
                        width="5.936"
                        height="1.783"
                        rx="0.891"
                        transform="translate(-8170.561 2335.191)"
                        fill="#8cc2fd"
                    />
                    <rect
                        id="사각형_6459"
                        data-name="사각형 6459"
                        width="12.033"
                        height="1.783"
                        rx="0.891"
                        transform="translate(-8170.561 2338.972)"
                        fill="#8cc2fd"
                    />
                    <rect
                        id="사각형_6460"
                        data-name="사각형 6460"
                        width="12.033"
                        height="1.783"
                        rx="0.891"
                        transform="translate(-8170.561 2342.569)"
                        fill="#8cc2fd"
                    />
                    <rect
                        id="사각형_6461"
                        data-name="사각형 6461"
                        width="12.033"
                        height="1.783"
                        rx="0.891"
                        transform="translate(-8170.561 2348.628)"
                        fill="#8cc2fd"
                    />
                    <rect
                        id="사각형_6462"
                        data-name="사각형 6462"
                        width="12.033"
                        height="1.783"
                        rx="0.891"
                        transform="translate(-8170.561 2352.405)"
                        fill="#8cc2fd"
                    />
                    <g id="그룹_8567" data-name="그룹 8567" transform="translate(-8179 2345.055)">
                        <rect
                            id="사각형_6463"
                            data-name="사각형 6463"
                            width="29.263"
                            height="20.402"
                            rx="3"
                            transform="translate(0 0)"
                            fill="#1e8ffa"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Docs;
