import React from "react";

const Menu = (props, context) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="prefix__menu-24px"
            width={props.w ? props.w : "24"}
            height={props.h ? props.h : "24"}
            viewBox="0 0 24 24"
        >
            <path id="prefix__패스_6833" d="M0 0h24v24H0z" data-name="패스 6833" style={{ fill: "none" }} />
            <path
                id="prefix__패스_6834"
                d="M4 18h16a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zm0-5h16a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zM3 7a1 1 0 0 0 1 1h16a1 1 0 0 0 0-2H4a1 1 0 0 0-1 1z"
                data-name="패스 6834"
                style={{ fill: props.fill ? props.fill : "#fff" }}
            />
        </svg>
    );
};

export default Menu;
