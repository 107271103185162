import React, { useState } from "react";
import styles from "./styles.scss";
import * as file from "api/main.json";
import IntroBox from "components/IntroBox";
import { useTranslation } from 'react-i18next';

const GlosignAPI: React.SFC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.sectionOne}>
                <div className={styles.left}>
                    <h1>{file.info.title}</h1>
                    <h2>{t("더 전문적으로, 더 효율적으로.")}</h2>
                    <p>{t("조직의 환경과 필요에 맞는 글로싸인 기업 특화 서비스를 통해 달라진 업무환경을 경험해보세요.")}</p>
                    <div className={styles.btn}>
                        <button onClick={() => window.open("https://glosign.com/index", "_blank")}>{t("글로싸인 바로가기")}</button>
                    </div>
                </div>
                <div className={styles.right}>
                    <img src="./static/media/indexImg.png" alt="img" />
                </div>
            </div>
            <div className={styles.sectionTwo}>
                <h1>
                    {file.info.title}({file.info.version})
                </h1>
                {/* <p>{file.info.description}</p> */}
                <p>{t("글로싸인 API, 어떤 계약이든 적용하세요.")}</p>
                <div>
                    <span>{file.info.contact.name} :</span>
                    <span className={styles.blue}> {file.info.contact.email}</span>
                </div>
                <div>
                    <span> URL : </span>
                    <span className={styles.blue}>{file.info.contact.url}</span>
                </div>
            </div>
            <div className={styles.sectionThree}>
                {file.tags.map((value, idx) => {
                    return (
                        <div className={styles.introBoxes}>
                            <IntroBox key={idx} title={value.name} subtitle={file["x-tagGroups"][idx].name} desc={value.description} />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

interface IProps {}

export default GlosignAPI;
