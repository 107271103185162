import React from "react";

const Dropdown = (props, context) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.w ? props.w : "10"} height={props.h ? props.h : "6.396"} viewBox="0 0 10 6.396">
            <path
                id="prefix__패스_10796"
                fill={props.fill ? props.fill : "#6980a6"}
                d="M8.826 12.381l3.607 3.607a1.387 1.387 0 0 0 1.964 0L18 12.381A1.4 1.4 0 0 0 17.015 10H9.8a1.393 1.393 0 0 0-.974 2.381z"
                data-name="패스 10796"
                transform="translate(-8.414 -10)"
            />
        </svg>
    );
};

export default Dropdown;
