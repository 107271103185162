import React from "react";
import Guide from "screens/Guide";
import SDK from "screens/SDK";
import Introduce from "screens/Introduce";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import {withTranslation} from "react-i18next";

const App = () => (
    <Router>
        <Route exact path="/api" component={Introduce} />
        <Route exact path="/intro" component={Introduce} />
        <Route exact path="/auth" component={Introduce} />
        <Route path="/guide" component={Guide} />
        {/* <Route path="/sdk" component={SDK} /> */}

        <Route exact path="/" render={() => <Redirect to="/api" />} />
    </Router>
);

export default withTranslation()(App);
