import React, { useState, useEffect } from "react";
import * as file from "api/main.json";
import styles from "./styles.scss";
import Box from "components/Box";
import LeftNavigation from "components/Navigation/LeftNavigation";
import HeadNavigation from "components/Navigation/HeadNavigation";
import SourceBox from "components/SourceBox";
import * as SVG from "components/SVG";
import { useTranslation } from "react-i18next";

import TryPopup from "components/TryPopup";
// import { S_IFCHR } from "constants";
// import data from "api/main.yaml"

const Guide: React.SFC<IProps> = (props) => {
    const { t } = useTranslation();
    const [copyPopup, setCopyPopup] = useState(false);
    const [sourceBox, setSourceBox] = useState(true);
    const [boxHeight, setBoxHeight] = useState([0]);
    const [activeMenu, setActiveMenu] = useState(0);
    const [mobileNavi, setMobileNavi] = useState(false);
    const [isOpenTry, setIsOpenTry] = useState(false);

    const [key, setKey] = useState("");

    const [req, setReq] = useState(undefined);
    const [headParam, setHeadParam] = useState(undefined);
    const [type, setType] = useState("");
    const [url, setUrl] = useState("");

    const [urlList, setUrlList] = useState([] as string[]);
    const [titleList, setTitleList] = useState([] as string[]);

    const [curType, setCurType] = useState({} as Record<string, string>);

    let realPaths = "";
    let allRestType = [""];
    let pathIdx = [0];
    let prevTag = "";

    useEffect(() => {
        const api_key = document.getElementById("api_key");
        const allObj = document.querySelectorAll("#contentwrap > div");
        let tempUrlList: string[] = urlList;
        let tempTitleList: string[] = titleList;

        Array.from(allObj).map((data: any, idx: number) => {
            let nowPath: Record<string, any>;
            let id = data.getAttribute("id");

            id = id.substring(9, id.length);
            pathIdx.push(id);
            nowPath = file.paths[pathIdx[idx + 1]];

            tempUrlList.push("/guide" + nowPath?.url + "/" + allRestType[idx + 1]);
            tempTitleList.push(nowPath?.[allRestType[idx + 1]].summary);
        });
        setUrlList(tempUrlList);
        setTitleList(tempTitleList);

        Array.from(allObj).map((data: any, idx: number) => {
            if (
                "/guide" + file.paths[pathIdx[idx + 1]].url + "/" + allRestType[idx + 1] ===
                decodeURI(window.location.pathname + window.location.search)
            ) {
                return _handleScroll(idx);
            }
        });
    }, []); // didMount()

    useEffect(() => {
        getBoxHeight();
        const el = document.querySelector("#contentwrap");
        const obj = document.querySelectorAll("#contentwrap > div");

        Array.from(obj).map((data: any) => {
            let id = data.getAttribute("id");
            id = id.substring(9, id.length);
            pathIdx.push(id);
        });

        if (el) {
            el.addEventListener("scroll", _listenScroll);
            return () => el.removeEventListener("scroll", _listenScroll);
        }
    });

    useEffect(() => {
        if (window.location.pathname === "/guide") {
            setActiveMenu(0);
            _handleScroll(0);
        }
    }, [window.location.pathname]);

    const _listenScroll = (e: any) => {
        const scrollTop = e.srcElement.scrollTop + 10; // 현재 스크롤(맨 위)

        boxHeight.map((box: any, idx: number) => {
            let prevPaths = realPaths;
            let paths = "/guide";
            paths += file.paths[pathIdx[idx + 1]].url + "/" + allRestType[idx + 1];
            if (box > scrollTop) {
                if (idx === 0) {
                    if (scrollTop > 0) {
                        if (prevPaths !== paths) {
                            _handleActiveMenu(idx);
                            window.history.pushState("", "", paths);
                            realPaths = paths;
                        }
                    }
                } else if (idx === boxHeight.length - 1) {
                    if (scrollTop + window.innerHeight === boxHeight[idx] + 80) {
                        // 80은 header 높이
                        _handleActiveMenu(idx);
                        window.history.pushState("", "", paths);
                        realPaths = paths;
                    } else if (scrollTop >= boxHeight[idx - 1]) {
                        if (scrollTop < boxHeight[idx]) {
                            if (prevPaths !== paths) {
                                _handleActiveMenu(idx);
                                window.history.pushState("", "", paths);
                                realPaths = paths;
                            }
                        }
                    }
                } else {
                    if (scrollTop >= boxHeight[idx - 1]) {
                        if (scrollTop < boxHeight[idx]) {
                            if (prevPaths !== paths) {
                                _handleActiveMenu(idx);
                                window.history.pushState("", "", paths);
                                realPaths = paths;
                            }
                        }
                    }
                }
            }
        });
    };

    const getBoxHeight = () => {
        // box 높이 구하기
        const box = document.querySelectorAll("#contentwrap > div");
        let tempList = boxHeight;
        let all = 0;
        Array.from(box).map((data: any, idx: number) => {
            if (tempList[idx] !== all + data.offsetHeight) {
                tempList[idx] = all + data.offsetHeight;
                setBoxHeight(tempList);
            }
            all += data.offsetHeight;
        });
    };

    const _handleCopyPopup = () => {
        setCopyPopup(true);
        window.setTimeout(() => {
            setCopyPopup(false);
        }, 3000); // 3초간 팝업을 띄움
    };

    const _handleSourceBox = () => {
        setSourceBox(!sourceBox);
    };

    const _handleActiveMenu = (idx: number) => {
        setActiveMenu(idx);
    };

    const _handleScroll = (idx: number) => {
        const wrap = document.querySelectorAll("#contentwrap > div");
        Array.from(wrap).map((data: any, index: number) => {
            if (idx === index) {
                data.scrollIntoView();
                // data.scrollIntoView({ behavior: "smooth" });
            }
        });
        _handleActiveMenu(idx);
    };

    const _handleMobileMenu = () => {
        setMobileNavi(!mobileNavi);
    };

    const _handleTryPopup = (req?: any, type?: string, url?: string, headParam?: any) => {
        if (req && type && url) {
            setReq(req);
            setType(type);
            setUrl(url);
        } else {
            setReq(undefined);
            setType("");
            setUrl("");
        }
        if (headParam) {
            setHeadParam(headParam);
        } else {
            setHeadParam(undefined);
        }
        setIsOpenTry(!isOpenTry);
    };

    const _handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKey(e.target.value);
    };

    const handleCurType = (url: string, type: string) => {
        let _curType: Record<string, string> = {};
        Object.keys(curType)?.map((key) => (_curType[key] = curType[key]));
        _curType[url] = type;
        setCurType(_curType);
    };

    return (
        <div className={styles.outerWrap}>
            <HeadNavigation currentPage="guide" handleMobileMenu={_handleMobileMenu} value={key} handleChangeInput={_handleChangeInput} />
            <div className={styles.innerWrap}>
                <div className={[styles.navi, mobileNavi ? styles.mobileNavi : undefined].join(" ")}>
                    <LeftNavigation type="guide" activeIdx={activeMenu} urlList={urlList} subTitleList={titleList} setScroll={_handleScroll} />
                </div>
                {mobileNavi ? <div className={styles.bg} onClick={_handleMobileMenu}></div> : null}
                <div className={styles.copyPopup} style={{ display: copyPopup ? "flex" : "none" }}>
                    {t("복사되었습니다.")}
                    <div onClick={() => setCopyPopup(false)}>
                        <SVG.Close fill="#1e8ffa" w="24" h="24" />
                    </div>
                </div>
                <div className={styles.contentWrap} id="contentwrap" style={!sourceBox ? { backgroundColor: "#fff" } : undefined}>
                    {file.paths.map((value: any, index: number) => {
                        let rest = Object.keys(value);
                        let restType = rest.filter((filter) => ["get", "post", "put", "delete"].includes(filter));
                        return restType.map((type: any, idx: number) => {
                            allRestType.push(type.toString());
                            let temp = prevTag;
                            prevTag = value[type].tags[0];
                            return (
                                <div className={styles.eachBox} id={`eachwrap-${index}`}>
                                    <div className={styles.api} style={!sourceBox ? { width: "100%" } : undefined}>
                                        <Box
                                            key={idx}
                                            headParam={value.parameters ? value.parameters : null}
                                            file={value[type]}
                                            type={type}
                                            url={value.url}
                                            prevTags={temp}
                                            curType={curType[value.url] ? curType[value.url] : "json"}
                                            getBoxHeight={getBoxHeight}
                                            handleTryPopup={_handleTryPopup}
                                        />
                                    </div>
                                    <div className={styles.source} style={!sourceBox ? { width: "0%" } : undefined}>
                                        <SourceBox
                                            file={value[type]}
                                            value={value}
                                            type={type}
                                            index={index}
                                            url={value.url}
                                            curType={curType[value.url] ? curType[value.url] : "json"}
                                            sourceBox={sourceBox}
                                            _handleCopyPopup={_handleCopyPopup}
                                            _handleSourceBox={_handleSourceBox}
                                            handleCurType={handleCurType}
                                        />
                                    </div>
                                </div>
                            );
                        });
                    })}
                </div>
            </div>
            {isOpenTry && (
                <TryPopup
                    req={req}
                    headParam={headParam}
                    type={type}
                    url={url}
                    api_key={key}
                    closePopup={_handleTryPopup}
                    curType={curType[url] ? curType[url] : "json"}
                />
            )}
        </div>
    );
};

interface IProps {}

export default Guide;
