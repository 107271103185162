import React, { useState } from "react";
import * as styles from "./styles.scss";

import * as SVG from "components/SVG";
import { hexToRgb } from "components/shared/hexToRgb";

import { useTranslation } from "react-i18next";

const URL = "https://api.glosign.com";
const DescBox: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [isPushing, setIsPushing] = useState(false);
    const [openDropdown, setOpenDropdown] = useState("");
    const [hover, setHover] = useState("");
    const [http, setHttp] = useState(-1);
    const [response, setResponse] = useState({});
    const [noAPI, setNoAPI] = useState(false);
    const [payload, setPayload] = useState({} as Record<any, any>);

    React.useEffect(() => {
        if (props.req) {
            let _payload: any;
            if (props.curType === "form") {
                _payload = props.req?.requestBody?.formContent;
            } else {
                _payload = props.req?.requestBody?.content;
            }
            setPayload(_payload);
        }
    }, [props.curType]);

    const handlePushBtn = () => {
        let func: Function = () => {};
        let data = new FormData();
        let json: Record<string, string> = {};
        let url = props.url;
        let header: Record<string, string> = {};
        let isJson: boolean = props.curType === "form" || props.req.requestBody?.content.type.includes("form-data") ? false : true;
        let content: any = props.req.requestBody?.content;

        if (props.curType === "form") {
            content = props.req?.requestBody?.formContent;
        }

        if (props.req.requestBody?.content || props.curType === "form") {
            // post, put
            if (props.headParam) {
                props.headParam.map((param: any) => {
                    let input: any = document.getElementById(param.name);
                    input = input?.value;
                    if (isJson) json[param.name] = input;
                    else data.append(param.name, input);
                    if (props.req.summary === "계약 재전송") {
                        url = url.replace("%7B", "{");
                        url = url.replace("%7D", "}");
                        url = url.replace("{" + param.name + "}", input);
                    }
                });
            }
            content.schema.properties.map((prop: any) => {
                let input: any = document.getElementById(prop.name);

                if (input.tagName === "TEXTAREA") {
                    input = input?.value;
                    if (input !== "") {
                        input = input.replace(/ /g, "");
                        input = input.replace(/'/g, '"');
                        let temp = input.split("\n");
                        let newStr: string = "";
                        temp.map((str: string) => {
                            let tempStr = str.split(":");
                            if (tempStr.length === 1) {
                                newStr += str;
                            } else {
                                tempStr.map((tStr: string, tIdx: number) => {
                                    let toStr: string = "";
                                    let toTemp = tStr.split("{");
                                    if (toTemp.length === 1) {
                                        if (tStr !== "") {
                                            if (tIdx === 0) {
                                                tStr = tStr.replace(/"/g, "");
                                                toStr += '"' + tStr + '":';
                                            } else {
                                                if (tStr.split(",").length > 1 && tStr.split(",")[1].length > 1) {
                                                    let splitStr = tStr.split(",")[1].replace(/"/g, "");
                                                    toStr += tStr.split(",")[0] + ",";
                                                    toStr += '"' + splitStr + '":';
                                                } else {
                                                    toStr += tStr;
                                                }
                                            }
                                        }
                                    } else {
                                        if (toTemp)
                                            toTemp.map((ttStr: string, ttIdx: number) => {
                                                if (ttIdx === 0) {
                                                    toStr += ttStr + "{";
                                                } else {
                                                    if (ttStr !== "") {
                                                        ttStr = ttStr.replace(/"/g, "");
                                                        toStr += '"' + ttStr + '":';
                                                    }
                                                }
                                            });
                                    }
                                    newStr += toStr;
                                });
                            }
                        });

                        newStr = newStr.replace(/,}/g, "}");
                        newStr = newStr.replace(/,]/g, "]");

                        if (newStr.substring(newStr.length - 1) === ",") {
                            newStr = newStr.substring(0, newStr.length - 1);
                        }

                        if (isJson) json[prop.name] = JSON.parse(newStr);
                        else data.append(prop.name, JSON.stringify(JSON.parse(newStr)));
                    }
                } else {
                    if (["file", "extraFiles", "attachments"].includes(prop.name)) input = input?.files[0];
                    else input = input?.value;
                    if (input === "" && prop.default !== undefined) {
                        input = prop.default;
                    }
                    if (isJson) json[prop.name] = input;
                    else data.append(prop.name, input);
                }

                if (isJson) {
                    header = {
                        Authorization: `Bearer ${props.api_key}`,
                        "Content-type": "application/json",
                    };
                } else {
                    header = {
                        Authorization: `Bearer ${props.api_key}`,
                    };
                }

                func = () => {
                    fetch(`${URL}/v1${url}`, {
                        //fetch(`http://localhost:7000/v1${url}`, {
                        method: props.type,
                        headers: header,
                        body: isJson ? JSON.stringify(json) : data,
                    }).then((res) => {
                        // console.log(res);
                        setHttp(res.status);

                        res.json()
                            .then((result) => {
                                // console.log("res", result);
                                setResponse(result);
                                if (result.detail && result.detail === "자격 인증데이터(authentication credentials)가 제공되지 않았습니다.") {
                                    setNoAPI(true);
                                }
                            })
                            .catch((e) => {
                                // console.log(e);
                                setResponse({});
                            });
                    });
                };
            });
        } else {
            // get, delete
            if (props.req.parameters) {
                props.req.parameters.map((param: any) => {
                    let input: any = document.getElementById(param.name);
                    input = input?.value;
                    if ((props.req.summary === "계약 조회" && param.name === "id") || param.name === "contract_id") {
                        url = url.replace("{contractId}", input);
                    } else if (props.req.summary === "서식 삭제") {
                        url = url.replace("%7B", "{");
                        url = url.replace("%7D", "}");
                        url = url.replace("/{template_id}", "?id=" + input);
                    } else {
                        url = url.replace("{" + param.name + "}", input);
                    }
                });
                if (url === props.url) {
                    let isFirst: boolean = true;
                    props.req.parameters.map((param: any, idx: number) => {
                        let input: any = document.getElementById(param.name);
                        input = input?.value;
                        if (input !== undefined && input !== "") {
                            if (idx === 0 || isFirst) {
                                url += "?" + param.name + "=" + input;
                                isFirst = false;
                            } else {
                                url += "&" + param.name + "=" + input;
                            }
                        }
                    });
                }
            }

            func = () => {
                fetch(`${URL}/v1${url}`, {
                    method: props.type,
                    headers: {
                        Authorization: `Bearer ${props.api_key}`,
                        "Content-Type": "application/json",
                    },
                }).then((res) => {
                    setHttp(res.status);
                    if (res.headers.get("content-Type") === "application/pdf") {
                        res.blob()
                            .then((blob) => {
                                let disposition = res.headers.get("content-Disposition");
                                let title = "";
                                if (disposition) {
                                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                    let matches = filenameRegex.exec(disposition);
                                    if (matches != null && matches[1]) {
                                        title = matches[1].replace(/['"]/g, "");
                                    }
                                }

                                if (window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(blob, title);
                                } else {
                                    let a = document.createElement("a");
                                    a.href = window.URL.createObjectURL(blob);
                                    a.download = title;
                                    document.body.appendChild(a);
                                    a.click();
                                    a.remove();
                                }
                                setIsPushing(true);
                            })
                            .catch((e) => {
                                setResponse({});
                                setIsPushing(true);
                            });
                    } else {
                        // console.log(res);

                        res.json()
                            .then((result) => {
                                // console.log("res", result);
                                setResponse(result);
                                if (result.detail && result.detail === "자격 인증데이터(authentication credentials)가 제공되지 않았습니다.") {
                                    setNoAPI(true);
                                }
                                setIsPushing(true);
                            })
                            .catch((e) => {
                                // console.log(e);
                                setResponse({});
                                setIsPushing(true);
                            });
                    }
                });
            };
        }
        func();
    };

    const handleDropdown = (name: string) => {
        if (openDropdown === name) {
            setOpenDropdown("");
        } else {
            setOpenDropdown(name);
        }
    };

    const setEnumText = (name: string, text: string) => {
        let doc: any = document.getElementById(name);
        doc.value = text;
        handleDropdown(name);
    };

    const uploadFile = (name: string, e: any) => {
        let nameWrap: any = document.getElementById(name + "-namewrap");
        let doc: any = document.getElementById(name);

        if (!document.getElementById(`${name}-filename`)) nameWrap.innerHTML = "";

        Object.values(doc.files).map((file: any) => {
            nameWrap.innerHTML += `<input type="text" id="${name}-filename" value="${file.name}" disabled />`;
        });
    };

    const returnStatus = (http: number) => {
        let str = "";
        props.req?.responses.map((res: any) => {
            if (http.toString() === res.code) {
                str = http + " " + res.description;
            }
        });
        if (str === "") {
            switch (http) {
                case 201:
                    str = http + " 성공적으로 생성되었습니다.";
                    break;
                case 400:
                    str = http + " 잘못된 요청입니다.";
                    break;
                case 404:
                    str = http + " 조회된 내역이 없습니다.";
                    break;
                default:
                    str = http + " 지금은 요청을 완료할 수 없습니다.";
            }
        }
        return str;
    };

    const returnString = (str: Record<string, any>) => {
        let stringJson: string = JSON.stringify(str);
        let newStr: string = "";
        let tab: string = "";

        stringJson = stringJson.replace(/\[{/gi, "[\n   {");
        stringJson = stringJson.replace(/}]/gi, "}\n]");
        stringJson = stringJson.replace(/{/gi, "{\n   ");
        stringJson = stringJson.replace(/,"/gi, ',\n   "');
        stringJson = stringJson.replace(/,{/gi, ",\n   {");
        stringJson = stringJson.replace(/}/gi, "\n}");
        stringJson = stringJson.replace(/", "/gi, '",\n   "');
        stringJson = stringJson.replace(/":/g, '": ');

        let tempStr = stringJson.split("\n");

        tempStr.map((data: string, idx: number) => {
            newStr += tab + data + "\n";
            if (idx !== 0 && (data.includes("{") || data.includes("["))) tab += "   ";
            if (data.includes("}") || data.includes("]")) tab = tab.substring(3, tab.length);
        });

        return newStr;
    };

    const placeHolder = (name: string) => {
        if (name === "contractList") {
            return `[ 
  {
    signOrder: boolean,
    contractName: "string",
    receiverList: [ 
      {
        signOrderNumber: number,
        name: "string",
        email: "string",
        userPhone: "string",
        userPhoneCode: "string",
        lang: kr,
        expired_day: 10,
        message: "string",
        redirectUrl: "string",
        coord: [ 
          {
            id: "string",
            label: "string",
            data: "any"
          } 
        ]
      } 
    ],
    tableData: [ 
      {
        "tableId": "string",
        body: [ 
          {
            "type": "string",
            "row_num": "number",
            cell: [ 
              {
                "col_num": "number",
                "colspan": "number",
                "value": "string",
                "valign": "string",
                "halign": "string",
                "font_size": "number"
              } 
            ]
          } 
        ],
        footer: [ 
          {
            "col_num": "number",
            "colspan": "number",
            "value": "string",
            "valign": "string",
            "halign": "string",
            "font_size": "number"
          } 
        ]
      } 
    ]
  } 
]
`;
        } else if (name === "tableData") {
            return `[ 
  {
    "tableId": "string",
    body: [ 
      {
        "type": "string",
        "row_num": "number",
        cell: [ 
          {
            "col_num": "number",
            "colspan": "number",
            "value": "string",
            "valign": "string",
            "halign": "string",
            "font_size": "number"
          } 
        ]
      } 
    ],
    footer: [ 
      {
        "col_num": "number",
        "colspan": "number",
        "value": "string",
        "valign": "string",
        "halign": "string",
        "font_size": "number"
      } 
    ]
  } 
]`;
        }
    };

    return (
        <>
            <div className={styles.wrap} style={http === -1 ? { width: "600px", left: "calc(50% - 300px)" } : undefined}>
                <div className={styles.left}>
                    <div className={styles.header}>
                        <div className={styles.title}>Try this API</div>
                        <div className={styles.sub}>
                            {t("API를 테스트할 수 있는 기능 입니다.")}{" "}{t("메뉴 오른쪽 상단에 api key를 설정 후 사용해 주세요.")}
                            <br />
                            {t("*표시는 필수조건")}
                        </div>
                        {!isPushing && (
                            <div className={styles.close} onClick={props.closePopup}>
                                <SVG.Close w={"30"} h={"30"} fill={"#8c9cb4"} />
                            </div>
                        )}
                    </div>
                    <div className={styles.body}>
                        <div className={styles.title}>{t(props.req.summary)}</div>
                        <div className={styles.sub}>{t(props.req.description)}</div>
                        {props.req.parameters !== undefined ? ( // get, delete
                            <>
                                <div className={[styles.title, styles.param].join(" ")}>Request parameters</div>
                                <div className={styles.params}>
                                    {props.req.parameters.map((data: any) => {
                                        return (
                                            <div className={styles.param}>
                                                <div className={styles.label}>
                                                    {data.required && <span>*</span>}
                                                    {data.name}
                                                </div>
                                                <div className={styles.input}>
                                                    {data.schema?.enum ? (
                                                        <>
                                                            <div onClick={() => handleDropdown(data.name)}>
                                                                <input
                                                                    type="text"
                                                                    className={styles.enum}
                                                                    id={data.name}
                                                                    placeholder={data.name}
                                                                    disabled
                                                                />
                                                                <div className={styles.dropdown}>
                                                                    <SVG.Dropdown />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={styles.wrapE}
                                                                style={openDropdown === data.name ? { display: "flex" } : undefined}
                                                            >
                                                                {data.schema.enum.map((value: string) => {
                                                                    return (
                                                                        <div className={styles.subE} onClick={() => setEnumText(data.name, value)}>
                                                                            {value}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </>
                                                    ) : data.name === "from" || data.name === "to" ? (
                                                        <input type="text" id={data.name} placeholder={"20200120"} maxLength={8} />
                                                    ) : (
                                                        <input
                                                            type={data.schema.type === "string" ? "text" : data.schema.type}
                                                            id={data.name}
                                                            placeholder={data.name}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : payload ? ( // post, put
                            <>
                                <div className={[styles.title, styles.param].join(" ")}>Request parameters</div>
                                <div className={styles.params}>
                                    {props.headParam
                                        ? props.headParam.map((param: any) => {
                                              return (
                                                  <div className={styles.param}>
                                                      <div className={styles.label}>
                                                          {param.required && <span>*</span>}
                                                          {param.name}
                                                      </div>
                                                      <div className={styles.input}>
                                                          <input
                                                              type={param.type === "string" ? "text" : param.type}
                                                              id={param.name}
                                                              placeholder={param.name}
                                                          />
                                                      </div>
                                                  </div>
                                              );
                                          })
                                        : null}

                                    {payload?.schema?.properties.map((data: any) => {
                                        return (
                                            <div className={styles.param}>
                                                <div className={styles.label}>
                                                    {props.req.requestBody.content.schema.required?.map((idx: string) => {
                                                        if (idx === data.name) {
                                                            return <span>*</span>;
                                                        }
                                                    })}
                                                    {data.name}
                                                </div>
                                                <div className={styles.input}>
                                                    {data.enum ? (
                                                        <>
                                                            <div onClick={() => handleDropdown(data.name)}>
                                                                <input
                                                                    type="text"
                                                                    className={styles.enum}
                                                                    id={data.name}
                                                                    placeholder={data.name}
                                                                    disabled
                                                                />
                                                                <div className={styles.dropdown} onClick={() => handleDropdown(data.name)}>
                                                                    <SVG.Dropdown />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={styles.wrapE}
                                                                style={openDropdown === data.name ? { display: "flex" } : undefined}
                                                            >
                                                                {data.enum.map((value: string) => {
                                                                    return (
                                                                        <div className={styles.subE} onClick={() => setEnumText(data.name, value)}>
                                                                            {value}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </>
                                                    ) : data.type.includes("file", "files") ? (
                                                        <div className={styles.fileBox}>
                                                            <div className={styles.nameWrap} id={`${data.name}-namewrap`}>
                                                                <input type="text" placeholder={t("선택된 파일 없음")} disabled />
                                                            </div>
                                                            <label
                                                                onMouseEnter={() => setHover("uploadBtn")}
                                                                onMouseLeave={() => setHover("")}
                                                                style={
                                                                    hover === "uploadBtn"
                                                                        ? { backgroundColor: `rgba(${hexToRgb("#e2f0ff", 0.8)})` }
                                                                        : undefined
                                                                }
                                                            >
                                                                {t("파일 업로드")}
                                                                <input
                                                                    id={data.name}
                                                                    type="file"
                                                                    accept={data.type.includes("files") ? "*" : "image/*"}
                                                                    multiple={data.type.includes("files")}
                                                                    onChange={(e) => uploadFile(data.name, e)}
                                                                />
                                                            </label>
                                                        </div>
                                                    ) : data.type === "boolean" ? (
                                                        <>
                                                            <div onClick={() => handleDropdown(data.name)}>
                                                                <input
                                                                    type="text"
                                                                    className={styles.enum}
                                                                    id={data.name}
                                                                    placeholder={data.name}
                                                                    disabled
                                                                />
                                                                <div className={styles.dropdown} onClick={() => handleDropdown(data.name)}>
                                                                    <SVG.Dropdown />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={styles.wrapE}
                                                                style={openDropdown === data.name ? { display: "flex" } : undefined}
                                                            >
                                                                <div className={styles.subE} onClick={() => setEnumText(data.name, "true")}>
                                                                    {"true"}
                                                                </div>
                                                                <div className={styles.subE} onClick={() => setEnumText(data.name, "false")}>
                                                                    {"false"}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : data.name === "contractList" || data.name === "tableData" ? (
                                                        <textarea id={data.name} placeholder={placeHolder(data.name)} />
                                                    ) : (
                                                        <input
                                                            type={data.type === "string" ? "text" : data.type}
                                                            id={data.name}
                                                            placeholder={data.name}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className={styles.footer}>
                        <div
                            className={styles.exeBtn}
                            onClick={handlePushBtn}
                            onMouseEnter={() => setHover("exeBtn")}
                            onMouseLeave={() => setHover("")}
                            style={hover === "exeBtn" ? { backgroundColor: `rgba(${hexToRgb("#1e8ffa", 0.8)})` } : undefined}
                        >
                            Execute
                        </div>
                        {/* <div className={styles.addTxt}>
                            By clicking above, I agree that my use of the API Explorer is governed The Terms and Privacy Policy.
                        </div> */}
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.close} onClick={props.closePopup}>
                        <SVG.Close w={"30"} h={"30"} fill={"#8c9cb4"} />
                    </div>
                    {http !== -1 && (
                        <div className={styles.body}>
                            <div className={styles.boxWrap}>
                                <div className={styles.returnBox}>
                                    {noAPI ? (
                                        <>
                                            <div className={styles.code} style={{ backgroundColor: "#f34471" }}>
                                                {http + " " + t("API Key를 확인해주세요.")}
                                            </div>
                                            <pre className={styles.value}></pre>
                                        </>
                                    ) : (
                                        <>
                                            <div className={styles.code} style={http >= 400 ? { backgroundColor: "#f34471" } : undefined}>
                                                {returnStatus(http)}
                                            </div>
                                            {response !== {} ? (
                                                <pre className={styles.value}>{returnString(response)}</pre>
                                            ) : (
                                                <pre className={styles.value}></pre>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.bg} onClick={props.closePopup}></div>
        </>
    );
};

interface IProps {
    req?: any;
    headParam?: any;
    type: string;
    url: string;
    api_key?: string;
    curType?: string;

    closePopup: () => void;
}

export default DescBox;
