import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { SideBar, Item, List, ItemList } from "./Item";
import { useTranslation } from "react-i18next";

const LeftNavigation: React.SFC<IProps> = (props) => {
    /*
        LeftNavigation 메뉴 추가 방법
        Guide page => 타이틀 메뉴는 titleList 배열에 추가, 서브 메뉴는 자동 추가
        SDK page => 타이틀 메뉴는 titleList 배열에 추가, 서브 메뉴는 SDK 내 index.tsx의 urlList, titleList 각각 추가
    */
    const { t } = useTranslation();

    const [clicked, setClicked] = useState(0);
    const [showList, setShowList] = useState(props.type === "guide" ? "User" : props.type === "sdk" ? "javascript SDK" : props.type);
    const [hover, setHover] = useState("");

    const [titleList, setTitleList] = useState(
        props.type === "guide"
            ? ["User", "Contract", "Docs", "Template", "Company"]
            : props.type === "sdk"
            ? ["javascript SDK"]
            : ["Glosign API", "Introduction", "Authentication"]
    ); // 타이틀 메뉴

    const [subList, setSubList] = useState([[]] as number[][]); // 타이틀 메뉴 당 서브 메뉴 개수 (순서대로 숫자로 들어감)
    const [urlList, setUrlList] = useState([] as string[]); // url 목록이 하나의 배열로 들어감

    useEffect(() => {
        /* urlList와 subList를 초기화함 */
        if (props.urlList !== undefined) {
            let tempList: number[][] = [];

            titleList.map(() => {
                tempList.push([]);
            });

            props.urlList.map((url: string, idx: number) => {
                let splitUrl = url.split("/")[2]; // guide | sdk | api ...

                titleList.map((title: string, index: number) => {
                    let tempTitle = title.split(" ")[0].toLowerCase();
                    if (splitUrl?.includes(tempTitle)) {
                        tempList[index].push(idx);
                    }
                });
            });

            if (props.urlList !== urlList) {
                // url 목록이 들어감
                setUrlList(props.urlList);
            }
            if (subList !== tempList && subList[0].length === 0 && tempList[0].length > 0) {
                // titleList마다 subList가 몇 개인지 들어감
                setSubList(tempList);
            }
        }
    });

    useEffect(() => {
        /* 새로고침 시 url에 따라 열려있는 타이틀 메뉴를 지정함 */
        if (props.onlyTitle) {
            let splitUrl = window.location.pathname.split("/")[1];
            props.urlList?.map((url: string, idx: number) => {
                if (url.includes(splitUrl)) {
                    setShowList(titleList[idx]);
                }
            });
        } else {
            let splitUrl = window.location.pathname.split("/")[2];
            titleList.map((title: string) => {
                let tempTitle = title.split(" ")[0].toLowerCase();
                if (splitUrl?.includes(tempTitle)) {
                    setShowList(title);
                }
            });
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (props.activeIdx !== undefined) {
            setClicked(props.activeIdx);
        }
    }, [props.activeIdx]);

    const _handleClick = (key: number, title: string) => {
        /* 타이틀 메뉴 클릭 시 호출 */

        // if (props.onlyTitle && props.urlList) {
            // 타이틀 메뉴만 있는 페이지 (api 소개)
        //     window.location.href = props.urlList[key];
        // }

        if (showList === title) {
            setShowList("");
        } else {
            setShowList(title);
        }

        _handleItemClicked(key, title);
    };

    const _handleItemClicked = (key: number, id: string) => {
        /* 서브 메뉴 클릭 시 호출 */
        if (props.setScroll) props.setScroll(key);
    };

    const _handleHover = (type: string) => {
        setHover(type);
    };

    return (
        <SideBar>
            {titleList.map((title: string, idx: number) => {
                return (
                    <Item
                        inner={
                            subList[0].length > 0 ? 
                                <Link to={urlList[subList[idx][0]]}>{title}</Link> 
                            : props.onlyTitle && props.urlList ?
                                <Link to={urlList[idx]}>{title}</Link>
                                :
                            title
                        }
                        title={title}
                        _ItemClicked={subList[0].length > 0 ? () => _handleClick(subList[idx][0], title) : () => _handleClick(idx, title)}
                        showList={showList}
                        _handleHover={_handleHover}
                        hover={hover}
                    >
                        {subList[0].length > 0 ? (
                            <List id={title}>
                                {subList[idx]?.map((sub: any,idx:number) => {
                                    const title = t(`${props.subTitleList?.[sub]}`)
                                    return (
                                        <ItemList
                                            key={idx}
                                            num={sub}
                                            _ListItemClicked={() => _handleItemClicked(sub, title)}
                                            clicked={clicked}
                                            _handleHover={_handleHover}
                                            hover={hover}
                                        >
                                            <Link to={urlList[sub]}>{title}</Link>
                                        </ItemList>
                                    );
                                })}
                            </List>
                        ) : null}
                    </Item>
                );
            })}
        </SideBar>
    );
};

interface IProps {
    type?: string;
    activeIdx?: number;
    urlList?: string[];
    subTitleList?: string[];
    onlyTitle?: boolean;

    setScroll?: (idx: number) => void;
}

export default LeftNavigation;
