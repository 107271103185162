import React from "react";

const User = (props, context) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.45" height="31.37" viewBox="0 0 26.45 31.37">
            <defs></defs>
            <g id="prefix__그룹_8503" data-name="그룹 8503" transform="translate(-182 -975.973)">
                <g id="prefix__그룹_8502" data-name="그룹 8502" transform="translate(182 973.75)">
                    <path
                        id="prefix__패스_10514"
                        d="M123.3 232.154l-6.557-.389-11.184 3.191.008 4.632h26.442l-.021-4.935z"
                        data-name="패스 10514"
                        transform="translate(-105.563 -205.995)"
                        style={{ fill: "#1e8ffa" }}
                    />
                    <path
                        id="prefix__패스_10517"
                        d="M138.314 237.3s-2.86 1.471-3.7.97-.153-5.184-.153-5.184-1.335 4.749-.229 5.518 4.082-1.304 4.082-1.304z"
                        class="prefix__cls-2"
                        data-name="패스 10517"
                        transform="translate(-124.801 -206.897)"
                        style={{ fill: "#1873c8" }}
                    />
                    <path
                        id="prefix__패스_10518"
                        d="M150.19 237.3s2.86 1.471 3.7.97.153-5.184.153-5.184 1.335 4.749.229 5.518-4.082-1.304-4.082-1.304z"
                        class="prefix__cls-2"
                        data-name="패스 10518"
                        transform="translate(-135.973 -206.897)"
                        style={{ fill: "#1873c8" }}
                    />
                    <path
                        id="prefix__패스_10519"
                        d="M135.495 216.125l.063 4.482 4.187 4.08 4.124-4.124.157-5.792z"
                        data-name="패스 10519"
                        transform="translate(-125.96 -194.415)"
                        style={{ fill: "#c7e2ff" }}
                    />
                    <path
                        id="prefix__패스_10515"
                        d="M137.274 174.081c1.963-.78 1.9 3.564.606 4.447-.976.664-1.226.518-1.226.518.029 3.119-1.4 3.582-1.922 3.987s-2.859 2.021-4.331 2.079-4.679-1.788-5.573-2.628c-.953-.9-1.242-3.205-1.242-3.205-2.8-.433-1.79-4.563-1.357-4.822s1.155.549 1.155.549l-.542-4.909 1.559-3.617 7.385-.065s3.963.845 3.985.91 1.503 6.756 1.503 6.756z"
                        data-name="패스 10515"
                        transform="translate(-116.554 -159.848)"
                        style={{ fill: "#ddedff" }}
                    />
                    <path
                        id="prefix__패스_10516"
                        d="M121.684 152.641a4.77 4.77 0 0 1 3.725-2.9c3.075-.693 3.985-1.559 6.67-.347s3.335 2.512 3.335 2.512 3.205.087 2.859 4.635-.736 5.891-1.169 5.977-.607-2.989-1.6-5.414-2.9-.086-5.977-.52-4.433-1.011-5.342.288-.433 5.717-1.213 6.151-2.631-4.881-1.288-10.382z"
                        data-name="패스 10516"
                        transform="translate(-116.224 -146.563)"
                        style={{ fill: "#2f2e41" }}
                    />
                </g>
            </g>
        </svg>
    );
};

export default User;
