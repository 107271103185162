import React from "react";

const Signature = (props, context) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="prefix__그룹_8402"
            width="31.449"
            height="34.357"
            data-name="그룹 8402"
            viewBox="0 0 31.449 34.357"
        >
            <defs></defs>
            <g id="prefix__그룹_8397" data-name="그룹 8397">
                <path
                    id="prefix__패스_9927"
                    d="M313.514 474.363l.289 1.083.133 1.43-.111 1.363a13.081 13.081 0 0 1-1.306 4.158 9.648 9.648 0 0 0-.9 1.944l-.4.69h-9.456c-.862-1.906-2-3.694-2.41-5.79-.549-2.835-.278-5.478 1.84-7.661a7.385 7.385 0 0 1 12.321 2.783z"
                    class="prefix__cls-1"
                    data-name="패스 9927"
                    transform="translate(-290.799 -469.319)"
                    style={{ fill: "#8cc2fd" }}
                />
                <path
                    id="prefix__패스_9928"
                    d="M292.425 596.569c.282.01.564.015.846.032a1.419 1.419 0 0 1 1.473 1.378 14.457 14.457 0 0 1 0 2 1.414 1.414 0 0 1-1.555 1.369c-1.258.017-2.517 0-3.776 0h-24.12c-1.522 0-1.963-.444-1.963-1.971v-.925c0-1.377.464-1.843 1.841-1.86.154 0 .308-.011.462-.017.282.007.564.02.846.02h24.947c.333.005.666-.015.999-.026z"
                    class="prefix__cls-1"
                    data-name="패스 9928"
                    transform="translate(-263.329 -567.002)"
                    style={{ fill: "#8cc2fd" }}
                />
                <path
                    id="prefix__패스_9929"
                    d="M300.035 580.8c-.333.011-.666.031-1 .031h-24.947c-.282 0-.564-.013-.846-.02a4.9 4.9 0 0 1 5.124-4.67c1.438-.007 2.877 0 4.315 0a1.205 1.205 0 0 0 .749-.092c2.146.051 4.291.088 6.436-.019a4.559 4.559 0 0 0 1.745.107c1.025.027 2.054.056 3.077 0a4.933 4.933 0 0 1 5.347 4.663z"
                    data-name="패스 9929"
                    transform="translate(-270.939 -551.231)"
                    style={{ fill: "#1e8ffa" }}
                />
                <path
                    id="prefix__패스_9930"
                    d="M318.5 546.01c-2.145.107-4.291.07-6.436.019a25.24 25.24 0 0 0-1.52-9.1h9.455l-.207.727a5.746 5.746 0 0 0-.525 1.7 4.338 4.338 0 0 0-.3 1.368 24.6 24.6 0 0 0-.467 5.286z"
                    data-name="패스 9930"
                    transform="translate(-299.574 -521.217)"
                    style={{ fill: "#1873c8" }}
                />
            </g>
        </svg>
    );
};

export default Signature;
