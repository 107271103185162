import React from "react";

const Template = (props, context) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="prefix__그룹_8482"
            width="27.263"
            height="33.356"
            data-name="그룹 8482"
            viewBox="0 0 27.263 33.356"
        >
            <defs></defs>
            <path
                id="prefix__사각형_5960"
                d="M0 0H23.02V29.603H0z"
                data-name="사각형 5960"
                transform="translate(0 3.753)"
                style={{ fill: "#8cc2fd" }}
            />
            <path
                id="prefix__사각형_5961"
                d="M0 0H23.02V29.603H0z"
                data-name="사각형 5961"
                transform="translate(4.243)"
                style={{ fill: "#1e8ffa" }}
            />
            <rect
                id="prefix__사각형_5962"
                width="5.875"
                height="1.764"
                class="prefix__cls-3"
                data-name="사각형 5962"
                rx=".882"
                transform="translate(9.909 4.442)"
                style={{ fill: "#f3f9ff" }}
            />
            <rect
                id="prefix__사각형_5963"
                width="11.909"
                height="1.764"
                class="prefix__cls-3"
                data-name="사각형 5963"
                rx=".882"
                transform="translate(9.909 9.747)"
                style={{ fill: "#f3f9ff" }}
            />
            <rect
                id="prefix__사각형_5964"
                width="11.909"
                height="1.764"
                class="prefix__cls-3"
                data-name="사각형 5964"
                rx=".882"
                transform="translate(9.909 13.306)"
                style={{ fill: "#f3f9ff" }}
            />
            <rect
                id="prefix__사각형_5965"
                width="11.909"
                height="1.764"
                class="prefix__cls-3"
                data-name="사각형 5965"
                rx=".882"
                transform="translate(9.909 17.044)"
                style={{ fill: "#f3f9ff" }}
            />
            <rect
                id="prefix__사각형_5966"
                width="11.909"
                height="1.764"
                class="prefix__cls-3"
                data-name="사각형 5966"
                rx=".882"
                transform="translate(9.909 20.781)"
                style={{ fill: "#f3f9ff" }}
            />
        </svg>
    );
};

export default Template;
