import React from "react";

const Arrow = (props, context) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.w ? props.w : "16.288"}
            height={props.h ? props.h : "16.288"}
            viewBox="0 0 16.288 16.288"
        >
            <g id="prefix__arrow_back_ios-24px" transform="rotate(90 8.144 8.144)">
                <path id="prefix__패스_4070" d="M16.288 16.288H0V0h16.288z" data-name="패스 4070" style={{ fill: "none", opacity: ".87" }} />
                <path
                    id="prefix__패스_4071"
                    d="M.271 12.2a.986.986 0 0 0 1.3 0l6.1-5.512a.617.617 0 0 0 0-.935L1.569.244a.986.986 0 0 0-1.3 0 .774.774 0 0 0 0 1.174l5.313 4.809-5.319 4.809A.772.772 0 0 0 .271 12.2z"
                    data-name="패스 4071"
                    transform="translate(4.446 1.917)"
                    style={{ fill: props.fill ? props.fill : "#dbdfe7" }}
                />
            </g>
        </svg>
    );
};

export default Arrow;
