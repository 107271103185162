import React, { useState } from "react";
import * as styles from "./styles.scss";

import { useTranslation } from "react-i18next";

const ReqList: React.FC<ReqListProps> = (props) => {
    const { t } = useTranslation();
    const [subBox, setSubBox] = useState(false);

    const _handleSubBox = async () => {
        await setSubBox(!subBox);
        props.getBoxHeight();
    };

    return (
        <>
            {props.value.name ? (
                <>
                    <div className={styles.reqItem}>
                        <div className={styles.type}>
                            <span>
                                <span />
                            </span>
                            <div
                                className={styles.name}
                                style={props.value.items || props.value.properties ? { cursor: "pointer" } : undefined}
                                onClick={props.value.items || props.value.properties ? _handleSubBox : undefined}
                            >
                                {props.value.name}
                                {props.value.items || props.value.properties ? (
                                    <div className={styles.arrow} style={subBox ? { transform: "rotate(90deg)" } : undefined}>
                                        {">"}
                                    </div>
                                ) : null}
                            </div>
                            {props.requiredList?.map((required: string) => {
                                return required === props.value.name ? <p className={styles.required}>required</p> : null;
                            })}
                        </div>
                        <div className={styles.value}>
                            <div>{props.value.type ? (props.value.type === "files array" ? "array" : props.value.type) : "any"}</div>
                            {props.value.enum ? (
                                <div className={styles.enumList}>
                                    Enum :
                                    {props.value.enum.map((value: any) => {
                                        return <li>{JSON.stringify(value)}</li>;
                                    })}
                                </div>
                            ) : null}
                            {JSON.stringify(props.value.default) ? (
                                <div className={styles.enumList}>
                                    Default : <li>{JSON.stringify(props.value.default)}</li>
                                </div>
                            ) : null}
                            <pre className={styles.schemaDesc}>{t(props.value.description)}</pre>
                        </div>
                    </div>
                    {subBox ? (
                        props.value.properties ? (
                            <div className={styles.subList} style={props.backColor ? { backgroundColor: "#fafbfd" } : { backgroundColor: "#fff" }}>
                                {props.value.properties.map((val: any) => {
                                    return (
                                        <ReqList
                                            value={val}
                                            getBoxHeight={props.getBoxHeight}
                                            backColor={!props.backColor}
                                            requiredList={props.value.required}
                                            idx={props.idx}
                                        />
                                    );
                                })}
                            </div>
                        ) : props.value.items?.properties ? (
                            <div className={styles.subList} style={props.backColor ? { backgroundColor: "#fafbfd" } : { backgroundColor: "#fff" }}>
                                {props.value.items.properties.map((val: any) => {
                                    return (
                                        <ReqList
                                            value={val}
                                            getBoxHeight={props.getBoxHeight}
                                            backColor={!props.backColor}
                                            requiredList={props.value.items.required}
                                            idx={props.idx}
                                        />
                                    );
                                })}
                            </div>
                        ) : null
                    ) : null}
                </>
            ) : (
                <>
                    {props.value.map((val: any) => {
                        return <ReqList value={val} getBoxHeight={props.getBoxHeight} requiredList={props.requiredList} idx={props.idx} />;
                    })}
                </>
            )}
        </>
    );
};

interface ReqListProps {
    getBoxHeight: () => void;
    value: any; //json properties
    backColor?: boolean;
    requiredList: any;
    idx: number;
}

export default ReqList;
