// import React, { useState } from "react";
import React from "react";
import styles from "./Item.scss";
import { Link } from "react-router-dom";

// interface SideBarProps {
//     id?: string;
// }

export const SideBar = (props: any) => {
    return (
        <div className={styles.sideBar}>
            <div className={styles.logo}>
                <Link to="/api">
                    <img src="https://www.glosign.co.kr/static/media/newindex/glosign-logo-blue.svg" alt="glosign logo" />
                </Link>
                <div className={styles.apiBox}>API</div>
            </div>
            <ul>{props.children}</ul>
        </div>
    );
};

interface ItemProps {
    inner?: object | string;
    title: string;
    _ItemClicked: any;
    showList?: string;
    _handleHover: (type: string) => void;
    hover: string;
}
export const Item: React.FC<ItemProps> = (props) => {
    return (
        <li className={styles.item}>
            <ItemText
                iclassName={[props.showList === props.title ? styles.itemClicked : props.hover === props.title ? styles.itemHover : null].join(" ")}
                ionClick={props._ItemClicked}
                ionMouseEnter={() => props._handleHover(props.title)}
                ionMouseLeave={() => props._handleHover("")}
            >
                {props.inner}
            </ItemText>
            <div className={[props.showList === props.title ? styles.activeList : styles.unactiveList].join(" ")}>{props.children}</div>
        </li>
    );
};

interface ItemTextProps {
    ionClick: () => void;
    iclassName: any;
    ionMouseEnter: () => void;
    ionMouseLeave: () => void;
}
export const ItemText: React.FC<ItemTextProps> = (props) => {
    return (
        <div
            className={[styles.itemText, props.iclassName].join(" ")}
            onClick={props.ionClick}
            onMouseEnter={props.ionMouseEnter}
            onMouseLeave={props.ionMouseLeave}
        >
            {props.children}
        </div>
    );
};

interface ListProps {
    title?: string;
    showList?: string;
    id: string;
}

export const List: React.FC<ListProps> = (props) => {
    return (
        <ul className={styles.list} id={props.id}>
            {/* <div className={styles.scrollBar} id="scrollbar" /> */}
            {props.children}
        </ul>
    );
};

interface ListItemProps {
    num: number;
    _ListItemClicked?: () => void;
    clicked: number;
    active?: boolean;
    _handleHover: (type: string) => void;
    hover: string;
}

export const ItemList: React.FC<ListItemProps> = (props) => {
    return (
        <li className={styles.itemList}>
            <div
                className={[
                    styles.inline,
                    props.num === props.clicked ? styles.litemClicked : props.hover === props.num.toString() ? styles.itemHover : null,
                ].join(" ")}
                onClick={props._ListItemClicked}
                onMouseEnter={() => props._handleHover(props.num.toString())}
                onMouseLeave={() => props._handleHover("")}
            >
                <div className={styles.scrollBar} />
                {props.children}
            </div>
        </li>
    );
};
