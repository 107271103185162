import React, { useState } from "react";
import styles from "./styles.scss";
import * as file from "api/main.json";
import * as SVG from "components/SVG";

export const SourceBox: React.SFC<IProps> = (props) => {
    const [hover, setHover] = useState("");
    const [subMenu, setSubMenu] = useState(false);
    const [sourceExpandBool, setSourceExpandBool] = useState(true);
    const [payloadExpandBool, setPayloadExpandBool] = useState(true);
    const [active, setActive] = useState(props.file.requestBody ? "payload" : "node");
    const [openType, setOpenType] = useState(false);

    let source: string = "";
    let payload: string = "";

    const _handleSubMenu = () => {
        setSubMenu(!subMenu);
    };

    const _handleCopy = (source: string) => {
        const tempBox = document.createElement("textarea");
        tempBox.style.opacity = "0";
        tempBox.value = source;

        document.body.appendChild(tempBox);
        tempBox.select();

        document.execCommand("copy");
        document.body.removeChild(tempBox);

        props._handleCopyPopup();
    };

    const _handleHover = (type: string) => {
        setHover(type);
    };

    const _handleClickLang = (type: string) => {
        setActive(type);
    };

    function _printSourceObject(items: any, tabStr = "", dataName = ""): string {
        let text = "";
        let tabtabStr = tabStr + "    ";
        text += tabStr + dataName + "{";
        if (items.hasOwnProperty("$ref")) {
            // *수정* $ref의 경로만 불러서 접근할 수 있게 바꾸기
            let loc: object; //경로
            loc = file.components.schemas.stampItem;
            return _printSourceObject(loc, tabStr);
        }
        if (!items.properties) return "";
        if (items.properties.hasOwnProperty("name")) {
            let data = items.properties;
            if (data.type === "array") {
                text += "\n" + tabtabStr + data.name + ": [ ";
                if (data.items) {
                    text += "\n";
                    text += _printSourceObject(data.items, tabStr + "        ");
                    text += "\n" + tabtabStr;
                    text += ",";
                }
                text = text.slice(0, -1);
                text += "] ";
            } else if (data.type === "object") {
                if (data.properties) {
                    text += "\n";
                    text += _printSourceObject(data, tabStr + "    ", data.name + ": ");
                    text += ",";
                }
            } else {
                text += "\n" + tabtabStr + JSON.stringify(data.name) + ": ";
                if (data.hasOwnProperty("default")) text += data.default;
                else if (data.hasOwnProperty("type")) text += JSON.stringify(data.type);
                else text += "null";
                text += ",";
            }
        } else {
            items.properties.map((data: any, idx: number) => {
                if (data.type === "array" || data.type === "files array") {
                    text += "\n" + tabtabStr + JSON.stringify(data.name) + ": [ ";
                    if (data.items) {
                        text += "\n";
                        text += _printSourceObject(data.items, tabStr + "        ");
                        text += "\n" + tabtabStr;
                        text += ",";
                    }
                    text = text.slice(0, -1);
                    text += "]";
                    if (idx < items.properties.length - 1) text += ", ";
                    else text += " ";
                } else if (data.type === "object") {
                    if (data.properties) {
                        text += "\n";
                        text += _printSourceObject(data, tabStr + "    ", JSON.stringify(data.name) + ": ");
                        text += ",";
                    }
                } else {
                    text += "\n" + tabtabStr + JSON.stringify(data.name) + ": ";
                    if (data.hasOwnProperty("default")) text += data.default;
                    else if (data.hasOwnProperty("type")) text += JSON.stringify(data.type);
                    else text += "null";
                    text += ",";
                }
            });
        }
        text = text.slice(0, -1);
        text += "\n" + tabStr + "} ";
        return text;
    }

    const handleSelectType = (type: string) => () => {
        setOpenType(false);
        if (type != props.curType) {
            props.handleCurType(props.url, type);
        }
    };

    return (
        <div className={styles.wrap}>
            {props.index === 0 && (
                <div className={styles.pageBtn} onClick={props._handleSourceBox} style={!props.sourceBox ? { right: "5px" } : undefined}>
                    <div className={styles.svg} style={!props.sourceBox ? { transform: "rotate(90deg)" } : undefined}>
                        <SVG.Arrow />
                    </div>
                </div>
            )}
            {props.sourceBox && (
                <div className={styles.testBox}>
                    <div className={styles.url} onClick={_handleSubMenu}>
                        <div
                            className={styles.type}
                            style={{ backgroundColor: props.type === "get" ? "#1fc11f" : props.type === "post" ? "#1e8ffa" : "#f34471" }}
                        >
                            {props.type === "delete" ? "DEL" : props.type}
                        </div>
                        <div className={styles.value}>{props.value.url}</div>
                        <div className={styles.svg} style={{ transform: subMenu ? "rotate(180deg)" : "rotate(0deg)" }}>
                            <SVG.Arrow />
                        </div>
                        {subMenu ? (
                            <ul className={styles.subMenu}>
                                {file.servers.map((data: any, index: number) => {
                                    return (
                                        <li>
                                            <div className={styles.serverDesc}>{data.description}</div>
                                            <div className={styles.serverUrl}>
                                                <div className={styles.urls}>
                                                    <span>{data.url}</span>
                                                    {props.value.url}
                                                </div>
                                                <div
                                                    className={styles.copy}
                                                    onClick={() => _handleCopy(data.url.concat(props.value.url))}
                                                    onMouseEnter={() => _handleHover(`copy${index}`)}
                                                    onMouseLeave={() => _handleHover("")}
                                                >
                                                    <SVG.Copy w={"16"} h={"18.5"} fill={hover === `copy${index}` ? "#1e8ffa" : undefined} />
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : null}
                    </div>
                    {props.file["x-code-samples"] ? (
                        <>
                            <div className={styles.api}>
                                <div className={styles.title}>Request samples</div>
                                <ul className={styles.lang}>
                                    {props.file.requestBody ? (
                                        <li
                                            className={[styles.list, active === "payload" ? styles.active : null].join(" ")}
                                            onClick={() => _handleClickLang("payload")}
                                        >
                                            payload
                                        </li>
                                    ) : null}

                                    {props.file["x-code-samples"].map((sample: any) => {
                                        return (
                                            <li
                                                className={[styles.list, active === sample.lang ? styles.active : null].join(" ")}
                                                onClick={() => _handleClickLang(sample.lang)}
                                            >
                                                {sample.lang}
                                            </li>
                                        );
                                    })}
                                </ul>
                                {active === "payload" && props.file.requestBody ? (
                                    <>
                                        <p>
                                            {props.curType === "form"
                                                ? (payload = _printSourceObject(props.file?.requestBody?.formContent?.schema))
                                                : (payload = _printSourceObject(props.file?.requestBody?.content?.schema))}
                                        </p>
                                        <pre className={styles.source}>
                                            <div className={styles.contentType}>
                                                <p>Content type</p>
                                                {props.file?.requestBody?.multiContent ? (
                                                    <div className={styles.toggleMenu}>
                                                        {props.curType === "form" ? (
                                                            <div
                                                                className={[styles.curType, openType ? styles.open : styles.close].join(" ")}
                                                                onClick={() => setOpenType(!openType)}
                                                            >
                                                                {props.file?.requestBody?.formContent?.type}
                                                                <SVG.Dropdown />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={[styles.curType, openType ? styles.open : styles.close].join(" ")}
                                                                onClick={() => setOpenType(!openType)}
                                                            >
                                                                {props.file?.requestBody?.content?.type}
                                                                <SVG.Dropdown />
                                                            </div>
                                                        )}
                                                        {openType ? (
                                                            <div className={styles.typeList}>
                                                                <div className={styles.eachType} onClick={handleSelectType("json")}>
                                                                    application/json
                                                                </div>
                                                                <div className={styles.eachType} onClick={handleSelectType("form")}>
                                                                    multipart/form-data
                                                                </div>
                                                            </div>
                                                        ) : undefined}
                                                    </div>
                                                ) : (
                                                    <div>{props.file.requestBody.content?.type}</div>
                                                )}
                                            </div>
                                            {payloadExpandBool ? payload : "{ ... }"}
                                        </pre>
                                        <div className={styles.buttons}>
                                            <div
                                                className={styles.copy}
                                                style={hover === "copyBtnPay" ? { opacity: 1 } : undefined}
                                                onClick={() => _handleCopy(payload)}
                                                onMouseEnter={() => _handleHover("copyBtnPay")}
                                                onMouseLeave={() => _handleHover("")}
                                            >
                                                Copy
                                            </div>
                                            <div
                                                className={styles.copy}
                                                style={hover === "expandBtnPay" ? { opacity: 1 } : undefined}
                                                onClick={() => {
                                                    setPayloadExpandBool(true);
                                                }}
                                                onMouseEnter={() => _handleHover("expandBtnPay")}
                                                onMouseLeave={() => _handleHover("")}
                                            >
                                                Expand all
                                            </div>
                                            <div
                                                className={styles.copy}
                                                style={hover === "collapseBtnPay" ? { opacity: 1 } : undefined}
                                                onClick={() => {
                                                    setPayloadExpandBool(false);
                                                }}
                                                onMouseEnter={() => _handleHover("collapseBtnPay")}
                                                onMouseLeave={() => _handleHover("")}
                                            >
                                                Collapse all
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    props.file["x-code-samples"].map((sample: any) => {
                                        if (active === sample.lang) {
                                            return (
                                                <>
                                                    <pre className={styles.source}>{sample.source}</pre>
                                                    <div className={styles.buttons}>
                                                        <div
                                                            className={styles.copy}
                                                            style={hover === "copyBtn" ? { opacity: 1 } : undefined}
                                                            onClick={() => _handleCopy(sample.source)}
                                                            onMouseEnter={() => _handleHover("copyBtn")}
                                                            onMouseLeave={() => _handleHover("")}
                                                        >
                                                            Copy
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                )}
                            </div>
                            <div className={styles.api}>
                                {props.file.responses
                                    ? props.file.responses.map((value: any) => {
                                          if (value.content) {
                                              return (
                                                  <>
                                                      <div className={styles.title}>Response samples</div>
                                                      <ul className={[styles.lang, styles.code].join(" ")}>
                                                          <li>{value.code}</li>
                                                      </ul>
                                                      <p>{(source = _printSourceObject(value.content.schema))}</p>
                                                      <pre className={styles.source}>
                                                          <div className={styles.contentType}>
                                                              <p>Content type</p>
                                                              <div>{value.content.type}</div>
                                                          </div>
                                                          {/* {sourceExpandBool ? source = _printSourceObject(value.content.schema) : source = "{-}"} */}
                                                          {sourceExpandBool ? source : "{ ... }"}
                                                      </pre>
                                                      <div className={styles.buttons}>
                                                          <div
                                                              className={styles.copy}
                                                              style={hover === "copyBtn200" ? { opacity: 1 } : undefined}
                                                              onClick={() => _handleCopy(source)}
                                                              onMouseEnter={() => _handleHover("copyBtn200")}
                                                              onMouseLeave={() => _handleHover("")}
                                                          >
                                                              Copy
                                                          </div>
                                                          <div
                                                              className={styles.copy}
                                                              style={hover === "expandBtn" ? { opacity: 1 } : undefined}
                                                              onClick={() => {
                                                                  setSourceExpandBool(true);
                                                              }}
                                                              onMouseEnter={() => _handleHover("expandBtn")}
                                                              onMouseLeave={() => _handleHover("")}
                                                          >
                                                              Expand all
                                                          </div>
                                                          <div
                                                              className={styles.copy}
                                                              style={hover === "collapseBtn" ? { opacity: 1 } : undefined}
                                                              onClick={() => {
                                                                  setSourceExpandBool(false);
                                                              }}
                                                              onMouseEnter={() => _handleHover("collapseBtn")}
                                                              onMouseLeave={() => _handleHover("")}
                                                          >
                                                              Collapse all
                                                          </div>
                                                      </div>
                                                  </>
                                              );
                                          }
                                      })
                                    : null}
                            </div>
                        </>
                    ) : null}
                </div>
            )}
        </div>
    );
};

interface IProps {
    file: any;
    value: any;
    type: string;
    index: number;
    sourceBox: boolean;
    url: string;
    curType: string;

    _handleCopyPopup: () => void;
    _handleSourceBox: () => void;
    handleCurType: (url: string, type: string) => void;
}

export default SourceBox;
