import React, { useState } from "react";
import styles from "./styles.scss";
import * as SVG from "components/SVG";
import * as json from "api/main.json";

import ResList from "./ResList";
import ReqList from "./ReqList";

import { hexToRgb } from "components/shared/hexToRgb";

import { useTranslation } from "react-i18next";

const User: React.SFC<IProps> = (props) => {
    const { t } = useTranslation();
    const [subBox, setSubBox] = useState(false);
    const [hover, setHover] = useState("");
    const tags = json.tags.filter((tags) => new RegExp(props.file.tags[0], "i").test(tags.name));

    const _handleSubBox = async () => {
        await setSubBox(!subBox);
        props.getBoxHeight();
    };

    const handleMouseEnter = (name: string) => {
        setHover(name);
    };
    const handleMouseLeave = () => {
        setHover("");
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.block}>
                {props.prevTags !== tags[0].name && (
                    <>
                        <div className={styles.header}>
                            <h1 className={styles.title}>{props.file.tags}</h1>
                            <p className={styles.description}>{t(`${tags[0].description}`)}</p>
                        </div>
                        <hr />
                    </>
                )}
                <div className={styles.content}>
                    <div className={styles.title}>
                        <h2>{t(`${props.file.summary}`)}</h2>
                        <div
                            className={[styles.type, props.type === "get" ? undefined : props.type === "post" ? styles.post : styles.delete].join(
                                " "
                            )}
                        >
                            {props.type === "delete" ? "DEL" : props.type}
                        </div>
                        {props.file.deprecated ? <div className={[styles.type, styles.deprecated].join(" ")}>DEPRECATED</div> : null}
                    </div>
                    <p className={styles.description}>{t(`${props.file.description}`)}</p>
                    {/* *수정* */}
                    {/* <div className={styles.descList}>
                        <ul>
                            <li>
                                <p>기능: 고객계좌에서 핀테크 기업 약정계좌로 핀-어카운트를 이용하여 출금합니다.</p>
                            </li>
                            <li>
                                <p>방식 : HTTPS-POST</p>
                            </li>
                            <li>
                                <p>호출정보 : DrawingTransfer.nh</p>
                            </li>
                            <li>
                                <p>호출결과 : JSON</p>
                            </li>
                        </ul>
                    </div> */}
                    <div className={styles.button}>
                        <div
                            className={styles.tryBtn}
                            style={hover === "try" ? { backgroundColor: `rgba(${hexToRgb("#1e8ffa", 0.8)})` } : undefined}
                            onMouseEnter={() => handleMouseEnter("try")}
                            onMouseLeave={handleMouseLeave}
                            onClick={
                                props.headParam
                                    ? () => props.handleTryPopup(props.file, props.type, props.url, props.headParam)
                                    : () => props.handleTryPopup(props.file, props.type, props.url)
                            }
                        >
                            Try it
                        </div>
                    </div>
                    <div className={styles.box}>
                        <div>
                            <p className={styles.auth}>AUTHORIZATIONS : </p>
                            <p className={styles.api}>{props.file.security.api_key ? props.file.security.api_key : "api_key"}</p>
                        </div>
                        {props.type === "get" || props.type === "delete" ? (
                            props.file.parameters ? (
                                <div className={styles.parameters}>
                                    {props.headParam ? (
                                        <>
                                            <p className={styles.auth}>{props.headParam[0].in} PARAMETERS : </p>
                                            <div>
                                                <div className={styles.type}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {props.headParam[0].name}
                                                    <p className={styles.required}>{props.headParam[0].required ? "required" : null}</p>
                                                </div>
                                                {props.headParam[0].schema ? (
                                                    <div className={styles.value}>
                                                        <div>{props.headParam[0].schema.type ? props.headParam[0].schema.type : "any"}</div>
                                                        {props.headParam[0].schema.default ? (
                                                            <div className={styles.enumList}>Defualt :{props.headParam[0].schema.default}</div>
                                                        ) : null}
                                                        <pre className={styles.schemaDesc}>{t(props.headParam[0].description)}</pre>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </>
                                    ) : null}
                                    <p className={styles.auth}>
                                        {props.file.parameters && props.file.parameters.length > 0 && props.file.parameters[0].in} PARAMETERS :{" "}
                                    </p>
                                    {props.file.parameters.map((data: any, idx: number) => {
                                        return (
                                            <div>
                                                <div className={styles.type}>
                                                    <span>
                                                        <span />
                                                    </span>
                                                    {data.name}
                                                    <p className={styles.required}>{data.required ? "required" : null}</p>
                                                </div>
                                                {data.schema ? (
                                                    <div className={styles.value}>
                                                        <div>{data.schema.type}</div>
                                                        {data.schema.enum ? (
                                                            <div className={styles.enumList}>
                                                                Enum :
                                                                {data.schema.enum.map((data: any, idx: number) => {
                                                                    return <li>{'"' + data + '"'}</li>;
                                                                })}
                                                            </div>
                                                        ) : null}
                                                        {data.example ? (
                                                            <div className={styles.enumList}>
                                                                Example :
                                                                <li>
                                                                    {/* {data.name}
                                                                    {"="} */}
                                                                    {data.example}
                                                                </li>
                                                            </div>
                                                        ) : data.schema.example ? (
                                                            <div className={styles.enumList}>
                                                                Example :
                                                                <li>
                                                                    {/* {data.name}
                                                                    {"="} */}
                                                                    {data.schema.example}
                                                                </li>
                                                            </div>
                                                        ) : null}
                                                        {data.schema.default ? (
                                                            <div className={styles.enumList}>
                                                                Default : <li>{'"' + data.schema.default + '"'}</li>
                                                            </div>
                                                        ) : null}
                                                        <pre className={styles.schemaDesc}>{t(data.description)}</pre>
                                                    </div>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null
                        ) : props.file.requestBody ? (
                            <div className={styles.parameters}>
                                {props.headParam ? (
                                    <>
                                        <p className={styles.auth}>{props.headParam[0].in} PARAMETERS : </p>
                                        <div>
                                            <div className={styles.type}>
                                                <span>
                                                    <span />
                                                </span>
                                                {props.headParam[0].name}
                                                <p className={styles.required}>{props.headParam[0].required ? "required" : null}</p>
                                            </div>
                                            {props.headParam[0].schema ? (
                                                <div className={styles.value}>
                                                    <div>{props.headParam[0].schema.type}</div>
                                                    {props.headParam[0].schema.default ? (
                                                        <div className={styles.enumList}>Defualt :{props.headParam[0].schema.default}</div>
                                                    ) : null}
                                                    <pre className={styles.schemaDesc}>{t(props.headParam[0].description)}</pre>
                                                </div>
                                            ) : null}
                                        </div>
                                    </>
                                ) : null}
                                <div>
                                    <p className={styles.auth}>REQUEST BODY SCHEMA :</p>
                                    {props.file?.requestBody?.multiContent ? (
                                        <p className={[styles.type, styles.req].join(" ")}>
                                            {props.curType === "json" ? props.file.requestBody.content.type : props.file.requestBody.formContent.type}
                                        </p>
                                    ) : (
                                        <p className={[styles.type, styles.req].join(" ")}>{props.file.requestBody.content.type}</p>
                                    )}
                                </div>
                                <div className={styles.reqList}>
                                    {props.curType === "json"
                                        ? props.file.requestBody.content?.schema.properties.map((data: any, idx: number) => {
                                              return (
                                                  <ReqList
                                                      value={data}
                                                      getBoxHeight={props.getBoxHeight}
                                                      backColor={true}
                                                      requiredList={props.file.requestBody.content?.schema.required}
                                                      idx={idx}
                                                  />
                                              );
                                          })
                                        : props.file.requestBody.formContent?.schema.properties.map((data: any, idx: number) => {
                                              return (
                                                  <ReqList
                                                      value={data}
                                                      getBoxHeight={props.getBoxHeight}
                                                      backColor={true}
                                                      requiredList={props.file.requestBody.formContent?.schema.required}
                                                      idx={idx}
                                                  />
                                              );
                                          })}
                                </div>
                            </div>
                        ) : (
                            <div className={styles.parameters}>
                                {props.headParam ? (
                                    <>
                                        <p className={styles.auth}>{props.headParam[0].in} PARAMETERS : </p>
                                        <div>
                                            <div className={styles.type}>
                                                <span>
                                                    <span />
                                                </span>
                                                {props.headParam[0].name}
                                                <p className={styles.required}>{props.headParam[0].required ? "required" : null}</p>
                                            </div>
                                            {props.headParam[0].schema ? (
                                                <div className={styles.value}>
                                                    <div>{props.headParam[0].schema.type}</div>
                                                    {props.headParam[0].schema.default ? (
                                                        <div className={styles.enumList}>Default :{props.headParam[0].schema.default}</div>
                                                    ) : null}
                                                    <pre className={styles.schemaDesc}>{t(props.headParam[0].description)}</pre>
                                                </div>
                                            ) : null}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        )}
                    </div>
                    {props.file.responses ? (
                        <div className={styles.response}>
                            <div className={styles.title}>Responses</div>
                            {props.file.responses.map((data: any, idx: number) => {
                                return (
                                    <>
                                        <div
                                            className={[
                                                styles.resList,
                                                data.code.substring(0, 1) === "2" ? styles.done : undefined,
                                                data.content ? styles.click : undefined,
                                            ].join(" ")}
                                            onClick={data.code.substring(0, 1) === "2" && data.content ? _handleSubBox : undefined}
                                        >
                                            {data.code} {t(`${data.description}`)}
                                            {data.content ? (
                                                <div onClick={_handleSubBox} style={subBox ? { transform: "rotate(180deg)" } : undefined}>
                                                    <SVG.Arrow fill={"#1fc11f"} />{" "}
                                                </div>
                                            ) : null}
                                        </div>
                                        {subBox && data.content ? (
                                            <div className={styles.subBox}>
                                                <div className={styles.response}>
                                                    <p>RESPONSE SCHEMA: </p>
                                                    <p>{data.content.type}</p>
                                                </div>
                                                {data.content.schema.properties ? (
                                                    <div className={[styles.resList].join(" ")}>
                                                        <ResList value={data.content.schema.properties} getBoxHeight={props.getBoxHeight} />
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

interface IProps {
    headParam?: any;
    file: any;
    type: string;
    url: string;
    curType: string;
    prevTags: string;

    getBoxHeight: () => void;
    handleTryPopup: (req?: any, type?: string, url?: string, headParam?: any) => void;
}

export default User;
