import { EN } from "./translate/en";

const translations = {
    en: {
        translation: EN,
    },
    options: {},
};

export default translations;
